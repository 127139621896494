import React from "react";

function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        fill="#fff"
        d="M5.01.708A5.01 5.01 0 000 5.718v6.193a.654.654 0 001.307 0V5.719A3.703 3.703 0 015.01 2.015h6.11a.654.654 0 000-1.307H5.01z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.203 3.796a38.663 38.663 0 00-8.544 0 1.752 1.752 0 00-1.545 1.533 37.103 37.103 0 000 8.62c.095.811.741 1.444 1.545 1.534 2.824.316 5.72.316 8.544 0a1.752 1.752 0 001.546-1.533 37.106 37.106 0 000-8.62 1.752 1.752 0 00-1.546-1.534zM4.805 5.095a37.355 37.355 0 018.253 0c.207.023.37.186.393.386a35.788 35.788 0 010 8.317.445.445 0 01-.393.386 37.36 37.36 0 01-8.253 0 .445.445 0 01-.393-.386 35.795 35.795 0 010-8.317.445.445 0 01.393-.386z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CopyIcon;

export const getAddressHidden = (address: string): string => {
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 4
  )}`;
};

export const changeSecondToDate = (
  data: Array<any>,
  field: string = "name",
  withDays: boolean = false
) => {
  if (!data) return [];
  return [...data].map((item) => {
    const date = new Date(item[field]);
    return {
      ...item,
      [field]: withDays
        ? `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
        : `${date.getMonth() + 1}.${date.getFullYear()}`,
    };
  });
};

export const getOneYearAgo = () => {
  return new Date().setMonth(new Date().getMonth() - 12);
};

import React, { Suspense } from "react";
import LoaderContainer from "src/containers/loaderContainer";

const Document = React.lazy(() => import("../modules/document"));

function DocumentPage() {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <Document />
    </Suspense>
  );
}

export default DocumentPage;

import React from "react";

function DiamondIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      fill="none"
      viewBox="0 0 16 14"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.912 0c-.437 0-.846.212-1.089.564L.14 4.46a.777.777 0 00-.052.8 23.973 23.973 0 006.005 7.44l1.31 1.086a.943.943 0 001.196 0l1.31-1.086a23.972 23.972 0 006.005-7.44.776.776 0 00-.052-.8L13.177.564A1.321 1.321 0 0012.087 0H3.913zm-.155 1.166a.189.189 0 01.155-.08h1.75L4.23 4.41a1.237 1.237 0 00-.075.236 53.514 53.514 0 01-1.577-.137l-1.052-.107 2.23-3.237zm-2.28 4.323a22.913 22.913 0 005.231 6.292L4.371 5.75a54.66 54.66 0 01-1.91-.162l-.984-.1zm4.118.329L8 12.022l2.405-6.204c-1.603.07-3.207.07-4.81 0zm6.034-.068l-2.337 6.03a22.915 22.915 0 005.231-6.29l-.983.1c-.636.064-1.273.118-1.91.16zm2.845-1.347l-1.052.107c-.525.053-1.051.1-1.577.137a1.237 1.237 0 00-.075-.236l-1.433-3.326h1.75c.063 0 .122.03.156.08l2.23 3.238zm-3.787.315c-1.79.09-3.584.09-5.373 0l1.564-3.633h2.244l1.565 3.633z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default DiamondIcon;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PortfolioResponse } from '../models/PortfolioResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PortfolioService {

    /**
     * Portfolio
     * @returns PortfolioResponse Successful Response
     * @throws ApiError
     */
    public static portfolioApiPortfolioGet(): CancelablePromise<PortfolioResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/portfolio/',
        });
    }

}

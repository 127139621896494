import React, { Suspense } from "react";
import LoaderContainer from "src/containers/loaderContainer";

const Rating = React.lazy(() => import("../modules/rating"));

function RatingPage() {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <Rating />
    </Suspense>
  );
}

export default RatingPage;

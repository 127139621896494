import DropdownMenu from "../dropdownMenu/dropdownMenu";
import React, { useState } from "react";

import NotificationIcon from "../../assets/icons/notificationIcon";
import { ButtonTheme } from "../../elements/button/button.types";
import Button from "../../elements/button";

const HeaderNotification = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <DropdownMenu
      isOpen={popoverOpen}
      positions={["bottom", "top"]}
      align="end"
      padding={8}
      containerStyle={{
          zIndex: "102",
      }}
      typeDropDown="notification"
      iconPosition={"end"}
      items={[
        {
          label: "Fill in the profile details",
          created_at: "one day ago",
          id: "1",
        },
        {
          label: "Fill in the profile details",
          created_at: "one day ago",
          id: "1",
        },
        {
          label: "Fill in the profile details",
          created_at: "one day ago",
          id: "1",
        },
        {
          label: "Fill in the profile details",
          created_at: "one day ago",
          id: "1",
        },

      ]}
      onItemClick={(id) => {
        setPopoverOpen(false);
      }}
      onClickOutside={() => setPopoverOpen(false)}
    >
      <div onClick={() => setPopoverOpen((prev) => !prev)}>
        <Button icon={<NotificationIcon />} theme={ButtonTheme.second} />
      </div>
    </DropdownMenu>
  );
};

export default HeaderNotification;

import React from "react";

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#D0C184"></circle>
      <path
        fill="#10131D"
        fillRule="evenodd"
        d="M9.114 5.042C9.861 5.008 10.098 5 12 5c1.902 0 2.14.008 2.885.042.746.034 1.255.153 1.7.325.467.176.89.452 1.24.808.357.35.631.772.807 1.24.173.445.292.954.326 1.698.034.748.042.986.042 2.887 0 1.902-.008 2.14-.042 2.886-.034.745-.153 1.254-.326 1.699-.176.467-.45.89-.807 1.24-.35.357-.773.631-1.24.807-.445.173-.954.292-1.699.326-.746.034-.984.042-2.886.042-1.902 0-2.14-.008-2.886-.042-.744-.034-1.254-.153-1.699-.326a3.435 3.435 0 01-1.24-.807 3.431 3.431 0 01-.808-1.24c-.172-.445-.29-.954-.325-1.699C5.008 14.14 5 13.902 5 12c0-1.902.008-2.14.042-2.885.034-.746.153-1.255.325-1.7.176-.468.452-.89.808-1.24.35-.356.773-.632 1.24-.808.445-.172.955-.29 1.7-.325zm5.715 1.26c-.739-.034-.96-.04-2.829-.04-1.869 0-2.09.006-2.829.04-.682.031-1.053.145-1.3.241-.326.127-.56.278-.805.523a2.171 2.171 0 00-.523.805c-.096.247-.21.618-.241 1.3-.034.739-.04.96-.04 2.829 0 1.869.006 2.09.04 2.829.031.682.145 1.053.241 1.3.112.303.291.579.523.805.226.232.502.41.805.523.247.096.618.21 1.3.241.739.034.96.04 2.829.04 1.87 0 2.09-.006 2.829-.04.682-.031 1.053-.145 1.3-.241.326-.128.56-.278.805-.523.232-.226.41-.502.523-.805.096-.247.21-.618.241-1.3.034-.739.04-.96.04-2.829 0-1.869-.006-2.09-.04-2.829-.031-.682-.145-1.053-.241-1.3a2.174 2.174 0 00-.523-.805 2.172 2.172 0 00-.805-.523c-.247-.096-.618-.21-1.3-.241zm-3.723 7.856a2.335 2.335 0 101.68-4.357 2.336 2.336 0 00-1.68 4.357zm-1.65-4.702a3.598 3.598 0 115.088 5.088 3.598 3.598 0 01-5.088-5.088zm6.94-.518A.85.85 0 1015.23 7.7a.85.85 0 001.166 1.237z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InstagramIcon;

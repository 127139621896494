/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum NotificationUpdates {
    USER = 'user',
    PAYMENTS = 'payments',
    REFERRALS = 'referrals',
    RATING = 'rating',
}

import React from "react";

function EngIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="18"
      fill="none"
      viewBox="0 0 26 18"
    >
      <path
        fill="#2E3192"
        d="M15.438 17.938h6.5c.803 0 1.485-.182 2.052-.497l-8.552-5.102v5.598zm10.424-3.165c.093-.413.14-.85.14-1.305v-2.03H20.27l5.59 3.335M.142 3.215A5.946 5.946 0 000 4.53v2.032h5.754L.142 3.215zM10.562.063h-6.5c-.797 0-1.475.178-2.04.49l8.54 5.094V.062zM2.05 17.46c.559.302 1.228.477 2.014.477h6.5v-5.556L2.049 17.46zM0 11.438v2.03c0 .468.05.919.148 1.343l5.654-3.373H0zM24.015.572c-.57-.324-1.262-.51-2.078-.51h-6.5V5.69L24.017.572zM26 6.562v-2.03c0-.445-.046-.874-.135-1.28l-5.548 3.31H26z"
      ></path>
      <path
        fill="#E6E7E8"
        d="M20.317 6.563l5.548-3.31c-.254-1.164-.873-2.127-1.85-2.68l-8.578 5.116V.062h-.812v7.313H26v-.813h-5.683zM0 10.625v.813h5.802L.147 14.81c.269 1.16.907 2.112 1.902 2.65l8.514-5.08v5.556h.812v-7.312H0zm26 0H14.625v7.313h.813v-5.599l8.552 5.102c.983-.548 1.61-1.505 1.87-2.667l-5.59-3.336H26v-.813zM10.562.062v5.585L2.023.552C1.037 1.097.406 2.053.143 3.215l5.612 3.348H0v.812h11.375V.062h-.813z"
      ></path>
      <path
        fill="#BE1E2D"
        d="M14.625 7.375V.062h-3.25v7.313H0v3.25h11.375v7.313h3.25v-7.313H26v-3.25H14.625z"
      ></path>
    </svg>
  );
}

export default EngIcon;

import React from "react";

function NotificationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 3a2 2 0 114 0 7 7 0 014 6v3a4 4 0 002 3H1a4 4 0 002-3V9a7 7 0 014-6M6 15v1a3 3 0 006 0v-1"
      ></path>
    </svg>
  );
}

export default NotificationIcon;

import { styled } from "@linaria/react";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { media } from "src/assets/styles/utils";
import { P1 } from "../../assets/styles/UIKit.styled";
import Loader from "../loader";
import { ButtonTheme, IButtonProps, IconPosition } from "./button.types";

const Button: FunctionComponent<PropsWithChildren<IButtonProps>> = (props) => {
  const {
    label,
    to = "",
    icon,
    disabled,
    onClick,
    children,
    theme = ButtonTheme.primary,
    iconPosition = IconPosition.start,
    pending,
    background,
    colorText,
    ...rest
  } = props;

  return (
    <div {...rest}>
      <ButtonContainer pending={pending} onClick={!disabled ? onClick : undefined}>
        <Buttonlayout disabled={disabled} theme={theme} background={background}>
          {pending ? (
            <Loader size="25px" />
          ) : (
            <>
              {icon && iconPosition === IconPosition.start && (
                <ButtonIcon>{icon}</ButtonIcon>
              )}
              {children}
              {label && (
                <ButtonText theme={theme}>
                  <P1 color={colorText}>{label}</P1>
                </ButtonText>
              )}
              {icon && iconPosition === IconPosition.end && (
                <ButtonIcon>{icon}</ButtonIcon>
              )}
            </>
          )}
        </Buttonlayout>
      </ButtonContainer>
    </div>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  pointer-events: ${({ pending }) => (pending ? "none" : "auto")};
`;

const ButtonIcon = styled.div`
  margin: auto 0;
  display: flex;
  align-items: center;
`;
const ButtonText = styled.span<{ theme: ButtonTheme }>`
  margin: auto 0;
  white-space: nowrap;
  & p {
    line-height: 100%;
    margin-top: 3px;
    font-size: ${({ theme }) => {
      switch (theme) {
        case ButtonTheme.primary:
        case ButtonTheme.second:
          return "16px";
        case ButtonTheme.linear:
        case ButtonTheme.simple:
          return "12px";
      }
    }};
    text-decoration: ${({ theme }) => {
      switch (theme) {
        case ButtonTheme.primary:
        case ButtonTheme.second:
        case ButtonTheme.simple:
          return "none";
        case ButtonTheme.linear:
          return "underline";
      }
    }};
  }
`;

const Buttonlayout = styled.button<{
  theme: ButtonTheme;
  background?: string;
  pending?: boolean;
}>`
  padding: ${({ theme }) => {
    switch (theme) {
      case ButtonTheme.primary:
        return "9px 30px";
      case ButtonTheme.second:
        return "15px";
      case ButtonTheme.linear:
        return "0px";
      case ButtonTheme.simple:
        return "10px";
    }
  }};
  background: ${({ theme, background }) => {
    if (background) {
      return background;
    }
    switch (theme) {
      case ButtonTheme.primary:
      case ButtonTheme.simple:
        return "var(--gradient-blue)";
      case ButtonTheme.second:
        return "var(--color-black-second)";
      case ButtonTheme.linear:
        return "none";
    }
  }};
  border-radius: 10px;
  transition: var(--transition-hover);
  cursor: pointer;
  display: flex;
  gap: 9px;
  width: 100%;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${media.middle} {
    padding: ${({ theme }) => {
      switch (theme) {
        case ButtonTheme.primary:
          return "9px 30px";
        case ButtonTheme.second:
          return "0px";
        case ButtonTheme.linear:
          return "0px";
        case ButtonTheme.simple:
          return "10px";
      }
    }};
    background: ${({ theme, background }) => {
      if (background) {
        return background;
      }
      switch (theme) {
        case ButtonTheme.primary:
        case ButtonTheme.simple:
          return "var(--gradient-blue)";
        case ButtonTheme.second:
          return "none";
        case ButtonTheme.linear:
          return "none";
      }
    }};
    text-decoration: ${({ theme }) => {
      switch (theme) {
        case ButtonTheme.primary:
        case ButtonTheme.second:
        case ButtonTheme.linear:
          return "none";
        case ButtonTheme.simple:
          return "underline";
      }
    }};
  }
`;

export default Button;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AreSigned } from '../models/AreSigned';
import type { Document } from '../models/Document';
import type { DocumentsList } from '../models/DocumentsList';
import type { SignRequest } from '../models/SignRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentsService {

    /**
     * Documents List
     * @param limit
     * @param offset
     * @returns DocumentsList Successful Response
     * @throws ApiError
     */
    public static documentsListApiDocumentsGet(
        limit: number = 50,
        offset?: number,
    ): CancelablePromise<DocumentsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign Document
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static signDocumentApiDocumentsSignPost(
        requestBody: SignRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/documents/sign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Are All Signed
     * @returns AreSigned Successful Response
     * @throws ApiError
     */
    public static areAllSignedApiDocumentsAreAllSignedGet(): CancelablePromise<AreSigned> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/are_all_signed',
        });
    }

    /**
     * Get Document
     * @param documentId
     * @returns Document Successful Response
     * @throws ApiError
     */
    public static getDocumentApiDocumentsDocumentIdGet(
        documentId: number,
    ): CancelablePromise<Document> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/{document_id}',
            path: {
                'document_id': documentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

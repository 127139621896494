import { StoreSlice } from "src/types/store";
import { ERROR_500_TEXT } from "../utils/transport";
import { PortfolioResponse, PortfolioService } from "../apiClient";
import { getPortfolioChartData } from "src/utils/getChartData";

export interface IPortfolio {
  items: {
    name: string;
    value: number;
  }[];
}

export interface IPortfolioErrors
  extends Record<keyof PortfolioResponse, string[]> {
  non_field_errors?: string[];
}

export interface IPortfolioState {
  portfolio?: IPortfolio;
  portfolioError?: IPortfolioErrors | string | null;
  portfolioStatus?: "initial" | "pending" | "error" | "success";
}

export interface IPortfolioEvent {
  "portfolio/send": () => void;
  "portfolio/pending": () => void;
  "portfolio/success": (portfolioData: IPortfolioResponse) => void;
  "portfolio/error": (error?: IPortfolioErrors | string) => void;
}

export type IPortfolioResponse = PortfolioResponse & {
  error?: IPortfolioErrors | string;
};

const requestPortfolio = async () =>
  PortfolioService.portfolioApiPortfolioGet();

export const portfolioModule: StoreSlice<IPortfolioState & IPortfolioEvent> = (
  set,
  get
) => ({
  portfolioError: null,
  portfolioStatus: "initial",

  "portfolio/send": async () => {
    try {
      get()["portfolio/pending"]();

      const response: IPortfolioResponse = await requestPortfolio();
      if (response) {
        get()["portfolio/success"](response);
      }
    } catch (err: any) {
      get()["portfolio/error"](err.statusText || ERROR_500_TEXT);
    }
  },
  "portfolio/pending": () => {
    set({
      portfolioError: null,
      portfolioStatus: "pending",
    });
  },
  "portfolio/success": (portfolioData) => {
    set({
      portfolio: {
        ...portfolioData,
        items: getPortfolioChartData(portfolioData.items),
      },
      portfolioError: null,
      portfolioStatus: "success",
    });
  },
  "portfolio/error": (error) => {
    set({
      portfolioError: error,
      portfolioStatus: "error",
    });
  },
});

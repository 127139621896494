/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MyStructureResponse } from '../models/MyStructureResponse';
import type { PartnerResponse } from '../models/PartnerResponse';
import type { PartnerSearchResponse } from '../models/PartnerSearchResponse';
import type { PointsHistoryResponse } from '../models/PointsHistoryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReferralsService {

    /**
     * Get User Info
     * @returns MyStructureResponse Successful Response
     * @throws ApiError
     */
    public static getUserInfoApiReferralsGet(): CancelablePromise<MyStructureResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/referrals/',
        });
    }

    /**
     * Get Partner Info
     * @param userId
     * @returns PartnerResponse Successful Response
     * @throws ApiError
     */
    public static getPartnerInfoApiReferralsUserIdGet(
        userId: number,
    ): CancelablePromise<PartnerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/referrals/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Points History
     * @param userId
     * @returns PointsHistoryResponse Successful Response
     * @throws ApiError
     */
    public static getPointsHistoryApiReferralsPointsHistoryUserIdGet(
        userId: number,
    ): CancelablePromise<PointsHistoryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/referrals/points_history/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Users In Structure
     * @param name
     * @param address
     * @param userId
     * @returns PartnerSearchResponse Successful Response
     * @throws ApiError
     */
    public static searchUsersInStructureApiReferralsSearchGet(
        name?: string,
        address?: string,
        userId?: number,
    ): CancelablePromise<PartnerSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/referrals/search/',
            query: {
                'name': name,
                'address': address,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

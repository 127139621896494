import React from "react";

function PersonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M3.063 3.719a3.719 3.719 0 117.437 0 3.719 3.719 0 01-7.438 0zM6.78 1.313a2.406 2.406 0 100 4.812 2.406 2.406 0 000-4.813zM3.281 10.063a1.969 1.969 0 00-1.969 1.968v1.04c0 .016.012.03.028.032 3.604.588 7.279.588 10.883 0a.032.032 0 00.027-.032v-1.04a1.969 1.969 0 00-1.969-1.969h-.298a.218.218 0 00-.068.011l-.757.248a7.657 7.657 0 01-4.753 0l-.758-.248a.218.218 0 00-.067-.01H3.28zM0 12.03A3.281 3.281 0 013.281 8.75h.299c.16 0 .321.026.475.076l.757.247a6.344 6.344 0 003.938 0l.758-.247c.153-.05.314-.076.475-.076h.298a3.281 3.281 0 013.281 3.281v1.04c0 .66-.477 1.221-1.128 1.327a35.082 35.082 0 01-11.306 0A1.345 1.345 0 010 13.071v-1.04z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PersonIcon;

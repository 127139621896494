import React from "react";

function RusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="18"
      fill="none"
      viewBox="0 0 26 18"
    >
      <path fill="#1B75BB" d="M0 6.156h26v5.688H0V6.156z"></path>
      <path
        fill="#E6E7E8"
        d="M21.938.063H4.063C1.37.063 0 2.063 0 4.53v1.625h26V4.531C26 2.063 24.63.063 21.937.063z"
      ></path>
      <path
        fill="#EC1C24"
        d="M0 13.469c0 2.468 1.37 4.469 4.063 4.469h17.875c2.692 0 4.062-2.001 4.062-4.47v-1.624H0v1.625z"
      ></path>
    </svg>
  );
}

export default RusIcon;

import React from "react";

function StudyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        fill="#fff"
        d="M17.563 4.933l-.857-.483L8.99.053h-.094A.892.892 0 008.733 0h-.317a.984.984 0 00-.172.053H8.15L.435 4.45a.868.868 0 00-.318.322.897.897 0 00.318 1.208l2.134 1.213v4.169c0 .7.271 1.37.753 1.865A2.54 2.54 0 005.141 14h6.858a2.54 2.54 0 001.819-.773 2.673 2.673 0 00.753-1.865V7.193l1.714-.984v2.515c0 .233.09.457.252.621.16.165.378.258.606.258a.847.847 0 00.606-.258.89.89 0 00.251-.621V5.698a.897.897 0 00-.118-.443.868.868 0 00-.32-.322zm-4.707 6.429a.89.89 0 01-.25.622.846.846 0 01-.607.257H5.141a.846.846 0 01-.606-.257.891.891 0 01-.251-.622V8.17l3.866 2.198.129.053h.077c.07.01.143.01.214 0 .071.01.143.01.214 0h.078a.398.398 0 00.128-.053l3.866-2.198v3.192zM8.57 8.6L2.62 5.206l5.95-3.394 5.95 3.394L8.57 8.6z"
      ></path>
    </svg>
  );
}

export default StudyIcon;

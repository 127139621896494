import React, { Suspense } from "react";
import LoaderContainer from "src/containers/loaderContainer";

const Promotions = React.lazy(() => import("../modules/promotions"));

function PromotionsPage() {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <Promotions />
    </Suspense>
  );
}

export default PromotionsPage;

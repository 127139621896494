import { styled } from "@linaria/react";
import React from "react";
import Loader from "src/elements/loader";

const LoaderContainer = () => {
  return (
    <Loading>
      <Loader />
    </Loading>
  );
}

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default LoaderContainer;

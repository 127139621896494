import React from "react";

function VkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#D0C184"></circle>
      <path
        fill="#10131D"
        d="M18.656 8.61c.105-.352 0-.61-.495-.61h-1.642c-.418 0-.609.223-.713.468 0 0-.835 2.056-2.018 3.39-.382.387-.556.51-.764.51-.104 0-.255-.123-.255-.475V8.61c0-.421-.12-.609-.469-.609H9.72c-.26 0-.417.195-.417.381 0 .4.591.492.652 1.617v2.44c0 .535-.095.633-.305.633-.556 0-1.91-2.064-2.713-4.426-.156-.459-.314-.644-.733-.644H4.563C4.093 8 4 8.224 4 8.469c0 .44.556 2.618 2.59 5.498C7.948 15.934 9.858 17 11.597 17c1.043 0 1.172-.237 1.172-.645v-1.487c0-.473.1-.568.43-.568.242 0 .66.124 1.634 1.071C15.944 16.495 16.128 17 16.753 17h1.641c.469 0 .703-.236.568-.704-.147-.465-.679-1.142-1.384-1.942-.383-.457-.956-.949-1.13-1.194-.244-.317-.173-.457 0-.738 0 0 2-2.844 2.208-3.812z"
      ></path>
    </svg>
  );
}

export default VkIcon;

import {StoreSlice} from "src/types/store";
import {ERROR_500_TEXT, getRequestToken} from "../utils/transport";
import {StoreEvents} from "./index";
import {
    AuthService,
    type SignInRequest,
    type UserPatchModel,
} from "../apiClient";

export interface IFullUser extends UserPatchModel {
    photo_id?: number;
    photo?: string;
    id?: number;
    address?: string;
    passport_id?: number;
    avatar_id?: number;
    parent_id?: number;
    invite_code?: string;
    is_membership_paid: boolean;
    finandy_id?: string
}

export interface IPictures {
    avatar_id?: {
        path: string;
        id: number;
        width: number;
        height: number;
    };
}

export interface IUserErrors extends Record<keyof IFullUser, string[]> {
    non_field_errors?: string[];
}

export interface IUserState {
  user?: IFullUser & IPictures;
  isLoggedIn?: boolean;
  isPassportIn?: boolean;
  isBanned?: boolean;
  userError?: IUserErrors | string | null;
  userStatus?:
    | "initial"
    | "login_pending"
    | "login_error"
    | "login_success"
    | "update_pending"
    | "update_error"
    | "update_success"
    | "invite_error"
    | "invite_success"
    | "invite_pending";

//   userRefreshInviteStatus: "initial" | "pending" | "error" | "success";
//   userRefreshInviteError: IUserErrors | string | null;
}

export interface IUserEvent {
  "user/reset": () => void;
  "user/check": (initialState: Partial<IUserState>) => void;
  "user/logout": () => void;

  "user/token/refresh": () => void;

  "user/login/send": (authData: SignInRequest) => void;
  "user/login/pending": () => void;
  "user/login/success": (userToken: IUserToken) => void;
  "user/login/error": (error?: IUserErrors | string) => void;

  "user/update/send": (user: Partial<IFullUser>) => void;
  "user/update/pending": () => void;
  "user/update/success": (userToken: IUserToken) => void;
  "user/update/error": (error?: IUserErrors | string) => void;

  "user/invite/set": (inviteCode: string) => void;
  "user/banned/set": () => void;

  "user/refresh_invite/send": () => void;
  "user/refresh_invite/success": (tokenData: IUserToken) => void;
  "user/refresh_invite/error": (error?: IUserErrors) => void;
  "user/refresh_invite/pending": () => void;

  // "user/identification/send": (user: Partial<IUser>) => void;
  // "user/identification/pending": () => void;
  // "user/identification/success": (userToken: IUserToken) => void;
  // "user/identification/error": (error?: IUserErrors | string) => void;
}

export type IUserToken = {
    access: string;
    refresh: string;
};

export type IUserResponse = IUserToken & {
    error?: IUserErrors | string;
};

// Определяем не протух ли токен
export const isTokenExpired = (token: string): boolean => {
    const jwt = JSON.parse(atob(token.split(".")[1]));
    const exp = (jwt && jwt.exp && jwt.exp * 1000) || null;
    if (!exp) {
        return false;
    }
    return Date.now() > exp;
};

// Достаем из токена данные по юзеру
export const getUserData = (token: string): IUserState["user"] => {
    const jwt = JSON.parse(atob(token.split(".")[1]));
    return {...jwt};
};

// Забираем токен из localstorage
export const getToken = (): IUserToken | null => {
    const token: string = localStorage.getItem("TOKEN_AUTH") || "null";
    return JSON.parse(token);
};

// Добавляем токен в localstorage
export const setToken = (token: IUserToken | null): void => {
    if (token) {
        localStorage.setItem("TOKEN_AUTH", JSON.stringify(token));
    } else {
        localStorage.removeItem("TOKEN_AUTH");
    }
};

const requestToken = async (data: SignInRequest) =>
    await AuthService.tokensGetApiAuthPost(data);

const requestUpdateInfo = async (data: UserPatchModel) =>
    await AuthService.tokensUserApiAuthUserPatch(data);

const requestRefreshInvite = async () =>
  await AuthService.refreshInviteCodeApiAuthUserRefreshInvitePost();

export const userModule: StoreSlice<
  IUserState & IUserEvent & Partial<Pick<StoreEvents, "network/reset">>
> = (set, get) => ({
  userError: null,
  userStatus: "initial",
  isBanned: false,

  "user/reset": () => {
    set({
      userError: null,
      userStatus: "initial",
    });
  },

  "user/check": (initialState) => {
    set({
      ...initialState,
      userError: null,
      userStatus: initialState.userStatus ? initialState.userStatus : "initial",
    });
  },

  "user/logout": () => {
    setToken(null);
    const networkReset = get()["network/reset"];

    if (networkReset) {
      networkReset();
    }

    set({
      user: undefined,
      isLoggedIn: false,
      isPassportIn: false,
      userError: null,
      userStatus: "initial",
    });
  },

  "user/token/refresh": async () => {
    const token = await getRequestToken(true);

    const state: Partial<IUserState> = {};

    if (!token) {
      state.isLoggedIn = false;
      state.isPassportIn = false;
    } else {
      const user = getUserData(token.access);

      state.user = user;
      state.isLoggedIn = Boolean(user?.first_name);
      state.isPassportIn = Boolean(user?.passport_id);
    }

    get()["user/check"](state);
  },

  "user/login/send": async (authData) => {
    try {
      get()["user/login/pending"]();
      const response = await requestToken({
        ...authData,
        invite_code: localStorage.getItem("INVITE_CODE") || "",
      });

      if (response.access) {
        setToken(response);
        get()["user/login/success"](response);
      }
    } catch (err: any) {
      get()["user/login/error"](
        err.body?.detail || err.statusText || ERROR_500_TEXT
      );
    }
  },

  "user/login/pending": () => {
    set({ userError: null, userStatus: "login_pending" });
  },

  "user/login/success": (userToken) => {
    const user = getUserData(userToken.access);

    set({
      user,
      isLoggedIn: Boolean(user?.first_name),
      isPassportIn: Boolean(user?.passport_id),
      userError: null,
      userStatus: "login_success",
    });
  },
  "user/login/error": (error) => {
    set({ userError: error, userStatus: "login_error" });
  },

  "user/update/send": async (user) => {
    try {
      get()["user/update/pending"]();
      const response = await requestUpdateInfo({
        is_membership_paid: get().user?.is_membership_paid || false,
        ...user,
      });

      if (response.access) {
        setToken(response);
        get()["user/update/success"](response);
      }
    } catch (err: any) {

      get()["user/update/error"](err.statusText || ERROR_500_TEXT);
    }
  },
  "user/update/pending": () => {
    set({ userError: null, userStatus: "update_pending" });
  },
  "user/update/success": (userToken) => {
    let user = getUserData(userToken.access);

    set({
      user,
      isPassportIn: Boolean(user?.passport_id),
      isLoggedIn: Boolean(user?.first_name),
      userError: null,
      userStatus: "update_success",
    });
  },
  "user/update/error": (error) => {
    set({ userError: error, userStatus: "update_error" });
  },

  "user/refresh_invite/send": async () => {
    try {
      get()["user/refresh_invite/pending"]();
      const response = await requestRefreshInvite();

      if (response.access) {
        setToken(response);
        get()["user/refresh_invite/success"](response);
      }
    } catch (err: any) {
      get()["user/refresh_invite/error"](
        err.body?.detail || err.statusText || ERROR_500_TEXT
      );
    }
  },

  "user/refresh_invite/pending": async () => {
    set({
      userError: null,
      userStatus: "invite_pending",
    });
  },

  "user/refresh_invite/error": async (error) => {
    set({ userError: error, userStatus: "invite_error" });
  },

  "user/refresh_invite/success": async (tokenData) => {
    let user = getUserData(tokenData.access);

    set({
      user,
      isPassportIn: Boolean(user?.passport_id),
      isLoggedIn: Boolean(user?.first_name),
      userError: null,
      userStatus: "invite_success",
    });
  },

  "user/invite/set": (inviteCode) => {
    localStorage.setItem("INVITE_CODE", inviteCode);
  },

  "user/banned/set": () => {
    set({
        isBanned: true
    })
  }
});

import {getToken, isTokenExpired, IUserToken, setToken} from "../store/user";
import {getDomen} from "./getDomen";
import {Mutex} from "async-mutex";

const mutex = new Mutex();
export const ERROR_500_TEXT = "Sorry, something went wrong";

const _getRequestToken = async (
  forceUpdate = false
): Promise<IUserToken | null> => {
  const token = getToken();
  if (!token) {
    return null;
  }

  if (isTokenExpired(token.refresh)) {
    setToken(null);
    return null;
  }

  if (isTokenExpired(token.access) || forceUpdate) {
    const updatedToken: IUserToken = await fetch(`/api/auth/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: token.refresh }),
    })
      .then((response) => response.json())
      .catch((error) => new Error(error));

    if (!updatedToken.access) {
      setToken(null);
      return null;
    }

    setToken({ access: updatedToken.access, refresh: token.refresh });
    return updatedToken;
  }

  return token;
};

export const getRequestToken = async (force_update = false) : Promise<IUserToken | null> => {
  return await mutex.runExclusive(async function () {
    return await _getRequestToken(force_update);
  });
}
import React from "react";

function RatingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M1 9a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zM5 5a1 1 0 011 1v8a1 1 0 11-2 0V6a1 1 0 011-1zM13 0a1 1 0 011 1v13a1 1 0 11-2 0V1a1 1 0 011-1zM9 6a1 1 0 011 1v7a1 1 0 11-2 0V7a1 1 0 011-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default RatingIcon;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromoDetailResponse } from '../models/PromoDetailResponse';
import type { PromoListResponse } from '../models/PromoListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PromoService {

    /**
     * Promo List
     * @param limit
     * @param offset
     * @returns PromoListResponse Successful Response
     * @throws ApiError
     */
    public static promoListApiPromoGet(
        limit: number = 50,
        offset?: number,
    ): CancelablePromise<PromoListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/promo/',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Promo Details
     * @param promoId
     * @param limit
     * @param offset
     * @returns PromoDetailResponse Successful Response
     * @throws ApiError
     */
    public static promoDetailsApiPromoPromoIdGet(
        promoId: number,
        limit: number = 20,
        offset?: number,
    ): CancelablePromise<PromoDetailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/promo/{promo_id}',
            path: {
                'promo_id': promoId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

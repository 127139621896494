/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RatingProfileResponse } from '../models/RatingProfileResponse';
import type { RatingResponse } from '../models/RatingResponse';
import type { RefreshTokenRequest } from '../models/RefreshTokenRequest';
import type { SignInRequest } from '../models/SignInRequest';
import type { TokensResponse } from '../models/TokensResponse';
import type { UserPatchModel } from '../models/UserPatchModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Tokens Get
     * @param requestBody
     * @returns TokensResponse Successful Response
     * @throws ApiError
     */
    public static tokensGetApiAuthPost(
        requestBody: SignInRequest,
    ): CancelablePromise<TokensResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tokens User
     * @param requestBody
     * @returns TokensResponse Successful Response
     * @throws ApiError
     */
    public static tokensUserApiAuthUserPut(
        requestBody: UserPatchModel,
    ): CancelablePromise<TokensResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/auth/user/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tokens User
     * @param requestBody
     * @returns TokensResponse Successful Response
     * @throws ApiError
     */
    public static tokensUserApiAuthUserPatch(
        requestBody: UserPatchModel,
    ): CancelablePromise<TokensResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/auth/user/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tokens Refresh
     * @param requestBody
     * @returns TokensResponse Successful Response
     * @throws ApiError
     */
    public static tokensRefreshApiAuthRefreshPost(
        requestBody: RefreshTokenRequest,
    ): CancelablePromise<TokensResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/refresh/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tokens Verify
     * @returns any Successful Response
     * @throws ApiError
     */
    public static tokensVerifyApiAuthVerifyPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/verify/',
        });
    }

    /**
     * Refresh Invite Code
     * @returns TokensResponse Successful Response
     * @throws ApiError
     */
    public static refreshInviteCodeApiAuthUserRefreshInvitePost(): CancelablePromise<TokensResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/user/refresh_invite/',
        });
    }

    /**
     * Profile
     * @returns any Successful Response
     * @throws ApiError
     */
    public static profileApiAuthProfileGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/profile/',
        });
    }

    /**
     * Rating
     * @param type
     * @param limit
     * @param offset
     * @returns RatingResponse Successful Response
     * @throws ApiError
     */
    public static ratingApiAuthRatingGet(
        type: 'top' | 'personal' | 'structural' = 'top',
        limit: number = 50,
        offset?: number,
    ): CancelablePromise<RatingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/rating/',
            query: {
                'type': type,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Rating Profile
     * @param type
     * @returns RatingProfileResponse Successful Response
     * @throws ApiError
     */
    public static ratingProfileApiAuthRatingProfileGet(
        type: 'top' | 'personal' | 'structural' = 'top',
    ): CancelablePromise<RatingProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/rating/profile/',
            query: {
                'type': type,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

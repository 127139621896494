import React, { Suspense } from "react";
import LoaderContainer from "src/containers/loaderContainer";

const NotFound = React.lazy(() => import("../modules/notFound"));

function NotFoundPage() {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <NotFound />
    </Suspense>
  );
}

export default NotFoundPage;

import { PromoService, Promo, PromoDetailResponse, PromoListResponse } from "src/apiClient";
import { StoreSlice } from "src/types/store";
import { ERROR_500_TEXT } from "src/utils/transport";

export interface IPromoState {
    PromoListResponse?: Array<Promo>
    PromoListResponseError: IPromoListResponseErrors | string | null
    PromoListResponseStatus: "initial" | "pending" | "error" | "success";

    PromoDetailResponse?: PromoDetailResponse
    PromoDetailResponseError: PromoDetailResponseErrors | string | null
    PromoDetailResponseStatus: "initial" | "pending" | "error" | "success";
}
export interface IPromoEvent {
    "promoListResponse/send": (limit: number, offset?: number) => void;
    "promoListResponse/pending": () => void;
    "promoListResponse/success": (profileData: Array<Promo>) => void;
    "promoListResponse/error": (error?: IPromoListResponseErrors | string) => void;

    "promoDetailResponse/send": (promoId: number, limit: number, offset?: number) => void;
    "promoDetailResponse/pending": () => void;
    "promoDetailResponse/success": (profileData: PromoDetailResponse) => void;
    "promoDetailResponse/error": (error?: PromoDetailResponseErrors | string) => void;
}

export interface IPromoListResponseErrors extends Record<keyof Array<Promo>, string[]> {
    non_field_errors?: string[];
}

export interface PromoDetailResponseErrors extends Record<keyof PromoDetailResponse, string[]> {
    non_field_errors?: string[];
}
const requestPromoListApiPromoGet = async (limit: number, offset?: number) => PromoService.promoListApiPromoGet(limit, offset)
const requestPromoDetailsApiPromoPromoIdGet = async (promoId: number, limit: number, offset?: number) => PromoService.promoDetailsApiPromoPromoIdGet(promoId, limit, offset)

export const promoModule: StoreSlice<IPromoState & IPromoEvent> = (
    set,
    get
) => ({
    PromoListResponseError: null,
    PromoListResponseStatus: "initial",

    PromoDetailResponseError: null,
    PromoDetailResponseStatus: "initial",

    "promoListResponse/send": async (limit: number, offset?: number) => {
        try {
            get()["promoListResponse/pending"]();
            const response = await requestPromoListApiPromoGet(limit, offset);
            if (response) {
                get()["promoListResponse/success"](response.items);
            }
        } catch (error: any) {
            get()["promoListResponse/error"](
              error.body?.detail || error.statusText || ERROR_500_TEXT
            );
        }
    },
    "promoListResponse/pending": () => {
        set({
            PromoListResponseError: null,
            PromoListResponseStatus: "pending",
        })
    },
    "promoListResponse/success": (promoListResponse) => {
        set({
          PromoListResponse: promoListResponse,
          PromoListResponseError: null,
          PromoListResponseStatus: "success",
        });
    },
    "promoListResponse/error": (error) => {
        set({
            PromoListResponseError: error,
            PromoListResponseStatus: "error",
        })
    },

    "promoDetailResponse/send": async (promoId, limit, offset) => {
        try {
            get()["promoDetailResponse/pending"]();
            const response = await requestPromoDetailsApiPromoPromoIdGet(promoId, limit, offset);
            if (response) {
                get()["promoDetailResponse/success"](response);
            }
        } catch (err: any) {
            get()["promoDetailResponse/error"](
              err.body?.detail || err.statusText || ERROR_500_TEXT
            );
        }
    },
    "promoDetailResponse/pending": () => {
        set({
            PromoListResponseError: null,
            PromoListResponseStatus: "pending",
        })
    },
    "promoDetailResponse/success": (promoDetailResponse) => {
        set({
            PromoDetailResponse: promoDetailResponse,
            PromoDetailResponseError: null,
            PromoDetailResponseStatus: "success",
        })
    },
    "promoDetailResponse/error": (error) => {
        set({
            PromoDetailResponseError: error,
            PromoDetailResponseStatus: "error",
        })
    },
});
import { css } from '@linaria/core'
import { reset } from './reset'

export const globals = css`
  :global() {
    ${reset};

    * {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
    body {
      background: black;
    }

    @font-face {
      font-family: "Gilroy";
      src: url("../fonts/Gilroy-Regular.ttf") format("truetype");
      font-style: normal;
      font-weight: 400;
    }
    @font-face {
      font-family: "Gilroy";
      src: url("../fonts/Gilroy-Medium.ttf") format("truetype");
      font-style: normal;
      font-weight: 500;
    }
    @font-face {
      font-family: "Gilroy";
      src: url("../fonts/Gilroy-Semibold.ttf") format("truetype");
      font-style: normal;
      font-weight: 600;
    }
  }
`;



import DropdownMenu from "../dropdownMenu/dropdownMenu";
import React, { useState } from "react";
import { styled } from "@linaria/react";
import ArrowIcon from "../../assets/icons/arrowIcon";
import WalletIcon from "../../assets/icons/walletIcon";
import { P1 } from "../../assets/styles/UIKit.styled";
import Avatar from "../../elements/avatar";
import LogoutIcon from "../../assets/icons/logoutIcon";
import { useStore } from "src/store";
import { getAddressHidden } from "src/utils/tools";
import { getDomen } from "src/utils/getDomen";
import { media } from "src/assets/styles/utils";
import { handleCopy } from "src/utils/handleCopy";
import CopyIcon from "src/assets/icons/copyIcon";

const HeaderProfile = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const user = useStore((store) => store.user);
  const userLogout = useStore((store) => store["user/logout"]);

  return (
    <DropdownMenu
      isOpen={popoverOpen}
      positions={["bottom", "top"]}
      align="end"
      padding={8}
      containerStyle={{
        zIndex: "102",
      }}
      iconPosition={"end"}
      items={[
        {
          label: "Скопировать адрес",
          id: "copy",
          icon: <CopyIcon />,
        },
        {
          label: "Выйти",
          id: "logout",
          icon: <LogoutIcon />,
        },
      ]}
      onItemClick={(id) => {
        if (id === "logout") {
          userLogout();
        }
        if (id === "copy") {
          handleCopy(user?.address || "");
        }
        setPopoverOpen(false);
      }}
      onClickOutside={() => setPopoverOpen(false)}
    >
      <WalletButton onClick={() => setPopoverOpen((prev) => !prev)}>
        <WalletIcon />
        <P1>{getAddressHidden(user?.address || "")}</P1>
        <Avatar
          size="xssmal"
          src={user?.avatar_id?.path && getDomen() + user?.avatar_id?.path}
        />
        <ArrowIcon />
      </WalletButton>
    </DropdownMenu>
  );
};

const WalletButton = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  ${media.middle} {
    & > p {
      display: none;
    }

    & > svg {
      display: none;
    }
  }

  & div {
    ${media.small} {
      width: 32px;
      height: 32px;
    }
  }
`;

export default HeaderProfile;

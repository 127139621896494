import type { Connector } from "@web3-react/types";
import type { Web3Provider } from "@ethersproject/providers";
import { StoreSlice } from "src/types/store";

export interface INetworkState {
  networkAccount?: string;
  networkChainId?: number;
  networkConnector?: Connector;
  networkError?: Error;
  networkIsActivating: boolean;
  networkIsActive: boolean;
  networkLastBlock: number | null;
  networkProvider?: Web3Provider;
}

export interface INetworkEvent {
  "network/set": (props: Omit<INetworkState, "networkLastBlock">) => void;
  "network/lastBlock/set": (block: number) => void;
  "network/reset": () => void;
  "network/createSign": (data: string, callback: (sig: string, ts: number) => void) => void
}

export const networkModule: StoreSlice<INetworkState & INetworkEvent> = (
  set,
  get
) => ({
  networkIsActive: false,
  networkIsActivating: true,
  networkLastBlock: null,

  "network/set": (props: Omit<INetworkState, "networkLastBlock">) => {
    if (props.networkProvider) {
      set((state) => ({
        ...props,
        ...state,
      }));
    }
  },

  "network/lastBlock/set": (block) => {
    set({
      networkLastBlock: block,
    });
  },

  "network/reset": () => {
    if (get().networkConnector) {
      get().networkConnector?.deactivate();
    }
  },

  "network/createSign": async (data, callback) => {
    try {
      if (get().networkProvider) {
        const signer = get().networkProvider!.getSigner();
        const address = await signer.getAddress();
        const ts = Math.trunc(new Date().getTime() / 1000);

        const signature: string = await signer.signMessage(`address=${address}&${data}&timestamp=${ts}`);
        callback(signature, ts)
      }
    } catch (err: any) {
//       alert(err.message ? err.message : "Error occured. Please, try again");
    }
  },
});

import {StoreSlice} from "src/types/store";
import {NotificationUpdates, WebsocketUpdate} from "../apiClient";
import {getToken, setToken} from "./user";
import {StoreEvents} from "./index";

export interface IWebSocketState {
    webSocket: WebsocketUpdate | null;
}

export interface IWebSocketEvent {
    "webSocket/onmessage": (data: WebsocketUpdate) => void;
}


export const webSocketModule: StoreSlice<IWebSocketState & IWebSocketEvent & Partial<StoreEvents>> = (
    set,
    get
) => ({
    webSocket: null,
    "webSocket/onmessage": async (data: WebsocketUpdate) => {
        try {
            const token = getToken();
            if (data.type === NotificationUpdates.USER && token) {
                await get()["user/token/refresh"]!()
            }
            if (data.type === NotificationUpdates.PAYMENTS && token) {
                await get()["payments/send"]!()
                await get()["portfolio/send"]!()
                await get()["payments/balance/send"]!()
            }
        } catch (err: any) {
            console.log(err)
        }
    },


});

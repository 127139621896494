import { styled } from "@linaria/react";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Header from "../containers/header/header";
import LeftBar from "./leftBar";
import { media } from "src/assets/styles/utils";

function LayoutLeftBar() {
  return (
    <Main>
      {/* header */}
      <Suspense>
        <LeftBarContainer>
          <LeftBar />
        </LeftBarContainer>
      </Suspense>

      {/* body */}
      <Suspense>
        <OutletContainer>
          <Header />
          <OutletContainerBody>
            <Outlet />
          </OutletContainerBody>
        </OutletContainer>
      </Suspense>
    </Main>
  );
}

const Main = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background: var(--color-black-first);
`;

const LeftBarContainer = styled.div`
  height: 100vh;
  min-width: 273px;
    ${media.large} {
      display: none;
    }
`;
const OutletContainer = styled.div`
  width: calc(100% - 273px);
  ${media.large} {
    width: 100%;
  }
`;

const OutletContainerBody = styled.div`
  max-width: 1056px;
  margin: 20px 30px 20px 80px;
  ${media.large} {
    margin: 20px 30px;
  }
  ${media.middle} {
    margin: 20px 30px;
  }
  ${media.small} {
    margin: 20px 16px;
  }
`;

export default LayoutLeftBar;

import {styled} from "@linaria/react"
import React, {FunctionComponent} from "react"
import NavBar from "../../elements/navBar";
import { media } from "src/assets/styles/utils";

interface IBurgerMenu {
    show: boolean
    showBurgerMenu: () => void
}

const BurgerMenu: FunctionComponent<IBurgerMenu> = (props) => {
    const {show, showBurgerMenu} = props

    return (
        <BurgerWrapper show={show}>
            <BurgerContent>
                <NavBar onClick={showBurgerMenu} type="burger"/>
            </BurgerContent>
        </BurgerWrapper>
    )
}

const BurgerWrapper = styled.div<{ show: boolean }>`
  position: fixed;
  opacity: ${(props) => (props.show ? "1" : "0")};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  top: 0;
  z-index: 9;
  left: 0;
  transition: var(--transition-hover);
  right: 0;
  bottom: 0;
  background: var(--color-black-second);
`

const BurgerContent = styled.div`
  display: flex;
  padding: 0 32px;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  margin-top: 140px;

  ${media.middle} {
    margin-top: 107px;
  }
  ${media.small} {

    margin-top: 95px;
  }
`

export default BurgerMenu

import { StoreSlice } from "src/types/store";
import { ERROR_500_TEXT } from "../utils/transport";
import {
  AuthService,
  RatingProfileResponse,
  RatingResponse,
  UserRating,
} from "../apiClient";
import { UserPoints, UserPointsFront } from "./profile";
import { getPieChartData } from "src/utils/getChartData";

export type RatingFilterType = "top" | "personal" | "structural"
export type IUserRating<T> = Omit<UserRating, "user_points"> & {
  user_points: T;
};
export interface IRating<T = UserPointsFront>
  extends Omit<RatingProfileResponse, "user_points"> {
  user_points: T;
}

export interface IRatingSearch<T = UserPointsFront>
  extends Omit<RatingResponse, "rating"> {
  rating: Array<IUserRating<T>>;
}

export interface IRatingErrors extends Record<keyof IRating<UserPoints>, string[]> {
  non_field_errors?: string[];
}

export interface IRatingSearchErrors
  extends Record<keyof IRatingSearch<UserPoints>, string[]> {
  non_field_errors?: string[];
}

export interface IRatingState {
  rating?: IRating;
  ratingError?: IRatingErrors | string | null;
  ratingStatus?: "initial" | "pending" | "error" | "success";

  ratingSearch?: IRatingSearch;
  ratingSearchError?: IRatingSearchErrors | string | null;
  ratingSearchStatus?: "initial" | "pending" | "error" | "success";
}

export interface IRatingEvent {
  "rating/send": (type: RatingFilterType) => void;
  "rating/pending": () => void;
  "rating/success": (ratingData: RatingProfileResponse) => void;
  "rating/error": (error?: IRatingErrors | string) => void;

  "rating/search/send": (type: RatingFilterType) => void;
  "rating/search/pending": () => void;
  "rating/search/success": (ratingSearchData: RatingResponse) => void;
  "rating/search/error": (error?: IRatingSearchErrors | string) => void;
}

export type IRatingResponse = IRating<UserPoints> & {
  error?: IRatingErrors | string;
};

export type IRatingSearchResponse = IRatingSearch<UserPoints> & {
  error?: IRatingSearchErrors | string;
};

const requestRatingProfile = async (type: RatingFilterType) =>
  AuthService.ratingProfileApiAuthRatingProfileGet(type);

const requestRatingSearch = async (type: RatingFilterType) =>
  AuthService.ratingApiAuthRatingGet(type);

export const ratingModule: StoreSlice<IRatingState & IRatingEvent> = (set, get) => ({
  ratingError: null,
  ratingStatus: "initial",

  "rating/send": async (type) => {
    try {
      get()["rating/pending"]();
      const response = await requestRatingProfile(type);
      if (response) {
        get()["rating/success"](response);
      }
    } catch (err: any) {
      get()["rating/error"](err.statusText || ERROR_500_TEXT);
    }
  },
  "rating/pending": () => {
    set({
      ratingError: null,
      ratingStatus: "pending",
    });
  },
  "rating/success": (ratingData) => {
    set({
      rating: { ...ratingData, user_points: getPieChartData(ratingData.user_points) },
      ratingError: null,
      ratingStatus: "success",
    });
  },
  "rating/error": (error) => {
    set({
      ratingError: error,
      ratingStatus: "error",
    });
  },

  "rating/search/send": async (type) => {
    try {
      get()["rating/search/pending"]();
      const response = await requestRatingSearch(type);
      if (response) {
        get()["rating/search/success"](response);
      }
    } catch (err: any) {
      get()["rating/search/error"](err.statusText || ERROR_500_TEXT);
    }
  },
  "rating/search/pending": () => {
    set({
      ratingSearchError: null,
      ratingSearchStatus: "pending",
    });
  },
  "rating/search/success": (ratingSearchData) => {
    const _ratingSearchData = {
      rating: ratingSearchData.rating.map((item) => ({
        ...item,
        user_points: getPieChartData(item.user_points)
      })),
    };
    set({
      ratingSearch: _ratingSearchData,
      ratingSearchError: null,
      ratingSearchStatus: "success",
    });
  },
  "rating/search/error": (error) => {
    set({
      ratingSearchError: error,
      ratingSearchStatus: "error",
    });
  },
});

import React from "react";

function StarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M15.973 6.052a.837.837 0 00-.264-.387.779.779 0 00-.423-.173l-4.546-.695L8.703.472a.825.825 0 00-.295-.344.773.773 0 00-.848 0 .825.825 0 00-.295.344L5.228 4.789l-4.546.703a.782.782 0 00-.401.187.84.84 0 00-.257.83.847.847 0 00.21.388l3.3 3.347-.799 4.752a.874.874 0 00.043.467.832.832 0 00.277.37.776.776 0 00.839.058l4.09-2.234 4.075 2.243c.112.066.238.1.367.1.17 0 .334-.055.471-.159a.832.832 0 00.277-.37.874.874 0 00.043-.467l-.799-4.752 3.3-3.346a.84.84 0 00.245-.388.876.876 0 00.01-.466zM11.06 9.4a.873.873 0 00-.232.745l.575 3.505L8.4 11.976a.77.77 0 00-.75 0l-3.004 1.673.575-3.505a.874.874 0 00-.028-.403.843.843 0 00-.204-.342l-2.397-2.51 3.364-.51a.78.78 0 00.358-.151.828.828 0 00.249-.31l1.422-3.187 1.502 3.196a.828.828 0 00.249.31.78.78 0 00.358.15l3.364.51L11.06 9.4z"
      ></path>
    </svg>
  );
}

export default StarIcon;

import React, { useEffect } from "react";
import { css } from "@linaria/core";
import "./App.css";
import RoutesApp from "./router";
import { getRequestToken } from "./utils/transport";
import { getUserData, IUserState } from "./store/user";
import { useStore } from "./store";
import useRedirect from "./hooks/useRedirect";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { links } from "./assets/const/links";
import { toast } from "react-hot-toast";

if (!window.location.host.includes("localhost"))
  Sentry.init({
    dsn: links.dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

function App() {
  const { userCheck, userStatus, userLogout } = useStore((store) => ({
    userCheck: store["user/check"],
    userStatus: store.userStatus,
    userLogout: store["user/logout"],
  }));

  useRedirect();
  
  useEffect(() => {
    const {ethereum} = window as any;
    if (ethereum) {
      ethereum.on("accountsChanged", (accounts: any) => {
        if (accounts.length > 0) {
          userLogout()
          window.location.reload();
        } else {
          toast.error("Вы не имеете подключенных аккаунтов")
        }
      });
    }
  }, [userLogout]);


  useEffect(() => {
    (async () => {
      const token = await getRequestToken();
      let initialState: Partial<IUserState> = {};

      if (!token) {
        initialState = {
          isLoggedIn: false,
          userStatus: userStatus,
        };
      } else {
        const user = getUserData(token.access);
        initialState = {
          user,
          isLoggedIn: Boolean(user?.first_name),
          isPassportIn: Boolean(user?.passport_id),
          userStatus: userStatus,
        };
      }
      userCheck(initialState);
    })();
  }, [userStatus]);

  return (
    <div className={template}>
      <RoutesApp />
    </div>
  );
}

const template = css`
  --font-base: "Gilroy", sans-serif;
  --color-gold: #d0c184;
  --color-green: #1eff91;
  --color-red: #ff4539;
  --color-black-first: #171c2b;
  --color-black-second: #10131d;
  --color-blue: #272f48;

  --color-white: #fff;
  --color-text-primary: #fff;
  --color-text-secondary: #909090;
  --color-text-secondary-1: #929292;
  --color-text-grey: #a4a4a4;
  --gradient-blue: linear-gradient(
    314.19deg,
    #324472 0%,
    #324472 0.01%,
    #233050 100%
  );
  --gradient-gold: linear-gradient(138.6deg, #d0c184 6.68%, #fff5cc 95.74%);
  --gradient-white: linear-gradient(315.17deg, #ffffff 0%, #9e9e9e 100%);
  --gradient-main: linear-gradient(
    98.98deg,
    rgba(39, 47, 72, 0.7) 4.05%,
    rgba(0, 0, 0, 0.15) 90.58%
  );

  --chart-light-blue: #a3bbea;
  --chart-gold: #d0c184;
  --chart-white: #fff;
  --chart-blue: #324472

  --font-text-xs: 12px;
  --font-text-s: 14px;
  --font-text-m: 16px;
  --font-text-xl: 18px;
  --font-text-l: 26px;
  --font-text-lx: 32px;

  --transition-hover: 0.3s;
`;

export { globals } from "./assets/styles/global";

export default App;

import { StoreSlice } from "src/types/store";
import { ERROR_500_TEXT } from "../utils/transport";
import {
  MyStructureResponse,
  PartnerResponse,
  PartnerSearchResponse,
  PointsHistoryResponse,
  PartnerSearch,
  ReferralsService,
  PartnerSearchParent,
  Partner,
} from "../apiClient";
import { getChartData, getPieChartData } from "src/utils/getChartData";
import { UserPoints, UserPointsFront } from "./profile";

export interface IPartnerSearchResponse<T = UserPoints> {
  items: Array<IPartnerSearch<T>>;
}

export interface IPartnerSearch<T>
  extends Omit<PartnerSearch, "user_points" | "parents"> {
  user_points: T;
  parents?: Array<PartnerSearchParent>;
}

export interface IReferralsErrors
  extends Record<keyof MyStructureResponse, string[]> {
  non_field_errors?: string[];
}

export interface IReferralsUserErrors
  extends Record<keyof PartnerResponse, string[]> {
  non_field_errors?: string[];
}

export interface IReferralsPointsErrors
  extends Record<keyof PointsHistoryResponse, string[]> {
  non_field_errors?: string[];
}

export interface IReferralsSearchErrors
  extends Record<keyof IPartnerSearchResponse, string[]> {
  non_field_errors?: string[];
}

export interface IReferrals
  extends Omit<MyStructureResponse, "personal_partners" | "user_points"> {
  user_points: UserPointsFront;
  personal_partners: Array<IPartner>;
}

export interface IPartner extends Omit<Partner, "user_points"> {
  user_points: UserPointsFront;
}

export interface IReferralsState {
  referrals?: IReferrals;
  referralsError?: IReferralsErrors | string | null;
  referralsStatus?: "initial" | "pending" | "error" | "success";

  referralsUser?: PartnerResponse;

  referralsPoints?: PointsHistoryResponse;

  referralsSearch?: IPartnerSearchResponse<UserPointsFront>;
  referralsSearchError?: IReferralsSearchErrors | string | null;
  referralsSearchStatus?: "initial" | "pending" | "error" | "success";

  referralsUsersCash: {
    [key: string]: {
      data: Omit<PartnerResponse, "user_points" | "personal_partners"> & {
        user_points: { name: string; value: number }[];
        personal_partners: IPartner[];
      };
      status: "initial" | "pending" | "error" | "success";
      error: IReferralsUserErrors | string | null;
    };
  };
  referralsPointsCash: {
    [key: string]: {
      data: {
        is_my: boolean;
        items: Array<Record<string, number>>;
      };
      status: "initial" | "pending" | "error" | "success";
      error: IReferralsPointsErrors | string | null;
    };
  };
}

export interface IReferralsEvent {
  "referrals/send": () => void;
  "referrals/pending": () => void;
  "referrals/success": (referralsData: IReferralsResponse) => void;
  "referrals/error": (error?: IReferralsErrors | string) => void;

  "referrals/user/send": (userId: number) => void;
  "referrals/user/pending": (userId: number) => void;
  "referrals/user/success": (
    referralsBalanceData: IReferralsUserResponse
  ) => void;
  "referrals/user/error": (
    userId: number,
    error?: IReferralsUserErrors | string
  ) => void;

  "referrals/points/send": (userId: number) => void;
  "referrals/points/pending": (userId: number) => void;
  "referrals/points/success": (
    userId: number,
    referralsIncomeData: IReferralsPointsResponse
  ) => void;
  "referrals/points/error": (
    userId: number,
    error?: IReferralsPointsErrors | string
  ) => void;

  "referrals/search/send": ({
    name,
    address,
    userId,
  }: {
    name?: string;
    address?: string;
    userId?: number;
  }) => void;
  "referrals/search/pending": () => void;
  "referrals/search/success": (
    referralsSearchData: IPartnerSearchResponse
  ) => void;
  "referrals/search/error": (error?: IReferralsSearchErrors | string) => void;
  "referrals/search/reset": () => void;
}

export type IReferralsResponse = MyStructureResponse & {
  error?: IReferralsErrors | string;
};

export type IReferralsUserResponse = PartnerResponse & {
  error?: IReferralsUserErrors | string;
};

export type IReferralsPointsResponse = PointsHistoryResponse & {
  error?: IReferralsPointsErrors | string;
};

export type IReferralsSearchResponse = PartnerSearchResponse & {
  error?: IReferralsSearchErrors | string;
};

const requestReferrals = async () =>
  ReferralsService.getUserInfoApiReferralsGet();

const requestReferralsUser = async (userId: number) =>
  ReferralsService.getPartnerInfoApiReferralsUserIdGet(userId);

const requestReferralsPoints = async (userId: number) =>
  ReferralsService.getPointsHistoryApiReferralsPointsHistoryUserIdGet(userId);

const requestReferralsSearch = async ({
  name,
  address,
  userId,
}: {
  name?: string;
  address?: string;
  userId?: number;
}) =>
  ReferralsService.searchUsersInStructureApiReferralsSearchGet(
    name,
    address,
    userId
  );

export const referralsModule: StoreSlice<IReferralsState & IReferralsEvent> = (
  set,
  get
) => ({
  referralsUsersCash: {},
  referralsPointsCash: {},

  referralsError: null,
  referralsStatus: "initial",
  referralsSearchError: null,
  referralsSearchStatus: "initial",

  "referrals/send": async () => {
    try {
      get()["referrals/pending"]();

      const response: IReferralsResponse = await requestReferrals();
      if (response) {
        get()["referrals/success"](response);
      }
    } catch (err: any) {
      get()["referrals/error"](err.statusText || ERROR_500_TEXT);
    }
  },
  "referrals/pending": () => {
    set({
      referralsError: null,
      referralsStatus: "pending",
    });
  },
  "referrals/success": (referralsData) => {
    const _referralsData = {
      ...referralsData,
      personal_partners: referralsData.personal_partners.map((item) => ({
        ...item,
        user_points: getPieChartData(item.user_points),
      })),
    };
    set({
      referrals: {
        ..._referralsData,
        user_points: getPieChartData(_referralsData.user_points),
      },
      referralsError: null,
      referralsStatus: "success",
    });
  },
  "referrals/error": (error) => {
    set({
      referralsError: error,
      referralsStatus: "error",
    });
  },

  "referrals/user/send": async (userId) => {
    const _referralsUsersCash = get().referralsUsersCash;
    if (_referralsUsersCash[userId]) {
      return;
    }
    try {
      get()["referrals/user/pending"](userId);

      const response: IReferralsUserResponse = await requestReferralsUser(
        userId
      );
      if (response) {
        get()["referrals/user/success"](response);
      }
    } catch (err: any) {
      get()["referrals/user/error"](userId, err.statusText || ERROR_500_TEXT);
    }
  },
  "referrals/user/pending": (userId) => {
    set({
      referralsUsersCash: {
        ...get().referralsUsersCash,
        [userId]: {
          ...get().referralsUsersCash[userId],
          status: "pending",
          error: null,
        },
      },
    });
  },
  "referrals/user/success": (referralsUserData) => {
    const _referralsUserData = {
      ...referralsUserData,
      personal_partners: referralsUserData.personal_partners.map((item) => ({
        ...item,
        user_points: getPieChartData(item.user_points)
      })),
    };
    set({
      referralsUser: referralsUserData,
      referralsUsersCash: {
        ...get().referralsUsersCash,
        [referralsUserData.id]: {
          data: {
            ..._referralsUserData,
            user_points: getPieChartData(_referralsUserData.user_points),
          },
          status: "success",
          error: null,
        },
      },
    });
  },
  "referrals/user/error": (userId, error) => {
    set({
      referralsUsersCash: {
        ...get().referralsUsersCash,
        [userId]: {
          ...get().referralsUsersCash[userId],
          status: "error",
          error: error
        },
      },
    });
  },

  "referrals/points/send": async (userId) => {
    const _referralsPointsCash = get().referralsPointsCash;
    if (_referralsPointsCash[userId]) {
      return;
    }
    try {
      get()["referrals/points/pending"](userId);

      const response: IReferralsPointsResponse = await requestReferralsPoints(
        userId
      );
      if (response) {
        get()["referrals/points/success"](userId, response);
      }
    } catch (err: any) {
      get()["referrals/points/error"](userId, err.statusText || ERROR_500_TEXT);
    }
  },
  "referrals/points/pending": (userId) => {
    set({
      referralsPointsCash: {
        ...get().referralsPointsCash,
        [userId]: {
          ...get().referralsPointsCash[userId],
          status: "pending",
          error: null,
        },
      },
    });
  },
  "referrals/points/success": (userId, referralsPointsData) => {
    set({
      referralsPoints: referralsPointsData,
      referralsPointsCash: {
        ...get().referralsPointsCash,
        [userId]: {
          data: {
            ...referralsPointsData,
            items: getChartData(referralsPointsData.items),
          },
          status: "success",
          error: null,
        },
      },
    });
  },
  "referrals/points/error": (userId, error) => {
    set({
      referralsPointsCash: {
        ...get().referralsPointsCash,
        [userId]: {
          ...get().referralsPointsCash[userId],
          status: "error",
          error: error,
        },
      },
    });
  },

  "referrals/search/send": async ({ name, address, userId }) => {
    try {
      get()["referrals/search/pending"]();

      const response = await requestReferralsSearch({
        name: name,
        address: address,
        userId: userId,
      });
      if (response) {
        get()["referrals/search/success"](response);
      }
    } catch (err: any) {
      get()["referrals/search/error"](err.statusText || ERROR_500_TEXT);
    }
  },
  "referrals/search/pending": () => {
    set({
      referralsSearchError: null,
      referralsSearchStatus: "pending",
    });
  },
  "referrals/search/success": (referralsSearchData) => {
    const _referralsSearchData = {
      items: referralsSearchData.items.map((item) => ({
        ...item,
        user_points: getPieChartData(item.user_points),
      })),
    };

    set({
      referralsSearch: _referralsSearchData,
      referralsSearchError: null,
      referralsSearchStatus: "success",
    });
  },
  "referrals/search/error": (error) => {
    set({
      referralsSearchError: error,
      referralsSearchStatus: "error",
    });
  },
  "referrals/search/reset": () => {
    set({
      referralsSearch: { items: [] },
      referralsSearchError: null,
      referralsSearchStatus: "initial",
    });
  },
});

import { dispatch } from "d3";
import { DocumentsService, DocumentsList, Document, SignRequest, AreSigned } from "src/apiClient";
import { StoreSlice } from "src/types/store";
import { ERROR_500_TEXT } from "src/utils/transport";

export interface IDocumentsState {
    documentsList: DocumentsList | null
    documentsListError: DocumentsErrors | string | null
    documentsListStatus: "initial" | "pending" | "error" | "success";

    document: Document | null,
    documentError: DocumentErrors | string | null,
    documentStatus: "initial" | "pending" | "error" | "success";

    signDocument: Document | null,
    signDocumentError: DocumentErrors | string | null,
    signDocumentStatus: "initial" | "pending" | "error" | "success";

    signDocumentGet: AreSigned | null,
    signDocumentGetError: DocumentErrors | string | null,
    signDocumentGetStatus: "initial" | "pending" | "error" | "success";
}
export interface IDocumentsEvent {
    "documentsList/send": (limit: number, offset?: number) => void;
    "documentsList/pending": () => void;
    "documentsList/success": (documentsList: DocumentsList) => void;
    "documentsList/error": (error?: DocumentsErrors | string) => void;

    "document/send": (id: number) => void;
    "document/pending": () => void;
    "document/success": (document: Document) => void;
    "document/error": (error?: DocumentErrors | string) => void;

    "signDocument/send": (requestBody: SignRequest) => void;
    "signDocument/pending": () => void;
    "signDocument/success": (signDocument: any) => void;
    "signDocument/error": (error?: any | string) => void;

    "signDocumentGet/send": () => void;
    "signDocumentGet/pending": () => void;
    "signDocumentGet/success": (signDocumentGet: AreSigned) => void;
    "signDocumentGet/error": (error?: any | string) => void;
}

export interface DocumentsErrors extends Record<keyof DocumentsList, string[]> {
    non_field_errors?: string[];
}
export interface DocumentErrors extends Record<keyof Document, string[]> {
    non_field_errors?: string[];
}

export interface sigDocumentErrors extends Record<keyof SignRequest, string[]> {
    non_field_errors?: string[];
}

const requestDocumentsListGet = async (limit: number, offset?: number) => DocumentsService.documentsListApiDocumentsGet(limit, offset)
const requestDocumentGet = async (id: number) => DocumentsService.getDocumentApiDocumentsDocumentIdGet(id)
const requestSignDocumentPost = async (requestBody: SignRequest) => DocumentsService.signDocumentApiDocumentsSignPost(requestBody)
const requestSignDocumentGet = async () => DocumentsService.areAllSignedApiDocumentsAreAllSignedGet()



export const documentsModule: StoreSlice<IDocumentsState & IDocumentsEvent> = (
    set,
    get
) => ({
    documentsList: null,
    documentsListError: null,
    documentsListStatus: "initial",

    document: null,
    documentError: null,
    documentStatus: "initial",

    signDocument: null,
    signDocumentError: null,
    signDocumentStatus: "initial",

    signDocumentGet: { status: true },
    signDocumentGetError: null,
    signDocumentGetStatus: "initial",

    "documentsList/send": async (limit: number, offset?: number) => {
        try {
            get()["documentsList/pending"]();
            const response = await requestDocumentsListGet(limit, offset);
            if (response) {
                get()["documentsList/success"](response);
            } else {
                get()["documentsList/error"](response);
            }
        } catch (error) {
            get()["documentsList/error"](ERROR_500_TEXT);
        }
    },
    "documentsList/pending": () => {
        set({
            documentsListError: null,
            documentsListStatus: "pending",
        })
    },
    "documentsList/success": (documentsList) => {
        set({
            documentsList: documentsList,
            documentsListError: null,
            documentsListStatus: "success",
        })
    },
    "documentsList/error": (error) => {
        set({
            documentsListError: error,
            documentsListStatus: "error",
        })
    },

    "document/send": async (id: number) => {
        try {
            get()["document/pending"]();
            const response = await requestDocumentGet(id);
            if (response) {
                get()["document/success"](response);
            } else {
                get()["document/error"](response);
            }
        } catch (error) {
            get()["document/error"](ERROR_500_TEXT);
        }
    },
    "document/pending": () => {
        set({
            documentError: null,
            documentStatus: "pending",
        })
    },
    "document/success": (document) => {
        set({
            document: document,
            documentError: null,
            documentStatus: "success",
        })
    },
    "document/error": (error) => {
        set({
            documentError: error,
            documentStatus: "error",
        })
    },

    "signDocument/send": async (requestBody: SignRequest) => {
        try {
            get()["signDocument/pending"]();
            const response = await requestSignDocumentPost(requestBody);
            if (response) {
                get()["signDocument/success"](response);
            } else {
                get()["signDocument/error"](response);
            }
        } catch (error) {
            get()["signDocument/error"](ERROR_500_TEXT);
        }
    },
    "signDocument/pending": () => {
        set({
            documentError: null,
            documentStatus: "pending",
        })
    },
    "signDocument/success": (signDocument) => {
        get()["documentsList/send"](10)
        get()["signDocumentGet/send"]()
        set({
            signDocument: signDocument,
            signDocumentError: null,
            signDocumentStatus: "success",
        })
    },
    "signDocument/error": (error) => {
        set({
            signDocumentError: error,
            signDocumentStatus: "error",
        })
    },

    "signDocumentGet/send": async () => {
        try {
            get()["signDocumentGet/pending"]();
            const response = await requestSignDocumentGet();
            if (response) {
                get()["signDocumentGet/success"](response);
            } else {
                get()["signDocumentGet/error"](response);
            }
        } catch (error) {
            get()["signDocumentGet/error"](ERROR_500_TEXT);
        }
    },
    "signDocumentGet/pending": () => {
        set({
            signDocumentGetError: null,
            signDocumentGetStatus: "pending",
        })
    },
    "signDocumentGet/success": (signDocumentGet) => {
        set({
            signDocumentGet: signDocumentGet,
            signDocumentGetError: null,
            signDocumentGetStatus: "success",
        })
    },
    "signDocumentGet/error": (error) => {
        set({
            signDocumentGetError: error,
            signDocumentGetStatus: "error",
        })
    }
});
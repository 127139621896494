import {styled} from "@linaria/react";
import React, {Suspense} from "react";
import {Outlet} from "react-router-dom";
import HeaderLangs from "src/containers/header/headerLang";
import ContentBlock from "../elements/contentBlock";
import { media } from "src/assets/styles/utils";
import Logo from "../assets/images/Logo.png";


function LayoutAuth() {
    return (
        <Main>
            {/* header */}
            <Suspense>
                <HeaderContainer>
                    <img src={Logo} width="185" alt="Logo"/>
                    <HeaderLangs/>
                </HeaderContainer>
            </Suspense>

            {/*body*/}
            <Suspense>
                <OutletContainer>
                    <ContentBlock>
                        <Outlet/>
                    </ContentBlock>
                </OutletContainer>
            </Suspense>
        </Main>
    );
}

const Main = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--color-black-first);
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 32px;
  ${media.small} {
    display: none;
  }
`;

const OutletContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  ${media.small} {
    background: var(--gradient-main);
    align-items: flex-start;
  }
  & > div {
    max-width: 840px;
    width: 100%;
    margin: 0 10px 130px;
    ${media.small} {
      margin: 0;
      border-radius: 0;
      height: 100%;
      padding: 100px 16px;
      background: none;
      border: none;
    }
  }
`;

export default LayoutAuth;

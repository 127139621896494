import {Routes, Route, useNavigate, useParams, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import ProfilePage from "./pages/profilePage";
import LayoutLeftBar from "./layouts/layoutLeftBar";
import LayoutAuth from "./layouts/layoutAuth";
import AuthPage from "./pages/authPage";
import RatingPage from "./pages/ratingPage";
import InvestmentsPage from "./pages/investmentsPage";
import DocumentsPage from "./pages/documentsPage";
import DocumentPage from "./pages/documentPage";
import StructurePage from "./pages/structurePage";
import ProfilePartnerPage from "./pages/profilePartnerPage";
import PromotionsPage from "./pages/promotionsPage";
import PromotionPage from "./pages/promotionPage";
import {useStore} from "./store";
import NotFoundPage from "./pages/notFoundPage";
import LayoutEmpty from "./layouts/layoutEmpty";
import MaintenancePage from "./pages/maintenancePage";
import { getToken } from "./store/user";

function RoutesApp() {

    const signDocumentGetSend = useStore(
        (store) => store["signDocumentGet/send"]
    );
    const {signDocumentGet, isLoggedIn, isPassportIn, user} = useStore(
        (store) => store
    );
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (isLoggedIn && isPassportIn)
            signDocumentGetSend()
    }, [signDocumentGetSend, isLoggedIn, isPassportIn])

    useEffect(() => {
        if (!signDocumentGet?.status && isLoggedIn && isPassportIn) {
            if (location.pathname.indexOf("document") === -1) {
                navigate("/documents")
            }
        } else if (isLoggedIn && !isPassportIn) {
            navigate("/auth/identification")
        }
    }, [signDocumentGet, navigate, isLoggedIn, isPassportIn, location.pathname])

    return (
        <Routes>
            <Route path="/" element={<LayoutLeftBar/>}>
                <Route index element={<ProfilePage/>}/>
            </Route>
            <Route path="/auth" element={<LayoutAuth/>}>
                <Route path=":authId" element={<AuthPage/>}/>
            </Route>
            <Route path="/rating" element={<LayoutLeftBar/>}>
                <Route index element={<RatingPage/>}/>
            </Route>
            <Route path="/investments" element={<LayoutLeftBar/>}>
                <Route index element={<InvestmentsPage/>}/>
            </Route>
            <Route path="/structure" element={<LayoutLeftBar/>}>
                <Route index element={<StructurePage/>}/>
            </Route>
            <Route path="/documents" element={<LayoutLeftBar/>}>
                <Route index element={<DocumentsPage/>}/>
            </Route>
            <Route path="/document/:documentId" element={<LayoutLeftBar/>}>
                <Route index element={<DocumentPage/>}/>
            </Route>
            <Route path="/education" element={<LayoutLeftBar/>}>
                <Route index element={<></>}/>
            </Route>
            <Route path="/promotions" element={<LayoutLeftBar/>}>
                <Route index element={<PromotionsPage/>}/>
            </Route>
            <Route path="/promotion/:promotionId" element={<LayoutLeftBar/>}>
                <Route index element={<PromotionPage/>}/>
            </Route>
            <Route path="/profilePartner/:partnerId" element={<LayoutLeftBar/>}>
                <Route index element={<ProfilePartnerPage/>}/>
            </Route>
            <Route path="*" element={<LayoutEmpty/>}>
                <Route path="*" element={<NotFoundPage/>}/>
            </Route>
            {/* <Route path="/" element={<LayoutEmpty />}>
        <Route index element={<MaintenancePage />} />
      </Route> */}
        </Routes>
    );
}

export default RoutesApp;

import {StoreSlice} from "src/types/store";
import {ERROR_500_TEXT} from "../utils/transport";
import {AuthService, PreparePaymentResponse} from "../apiClient";
import {getPieChartData} from "src/utils/getChartData";
import {metaMask} from "src/connectors/metaMask";
import {StoreState} from ".";
import {Contract} from "@ethersproject/contracts";
import bep20 from "../abi/bep20.abi.json"
import router from "../abi/Router.abi.json"
import {BigNumber} from "@ethersproject/bignumber";

export interface IContractsState {
    contractTokenApprove: boolean;
    contractTokenStatus: "initial" | "pending" | "error" | "success";
    contractTokenError: string | null;

    contractRouterStatus: "initial" | "pending" | "error" | "success";
    contractRouterError: string | null;
}

export interface IContractsEvent {
    "contractToken/send": () => void;
    "contractToken/get": () => void;
    "contractToken/pending": () => void;
    "contractToken/success": (date: any) => void;
    "contractToken/error": (err: string) => void;

    "contractRouter/set": (params: PreparePaymentResponse) => void
    "contractRouter/pending": () => void;
    "contractRouter/initial": () => void;
    "contractRouter/success": () => void;
    "contractRouter/error": (err: string) => void;
}


export const contractsModule: StoreSlice<IContractsState & IContractsEvent & Partial<StoreState>> = (
    set,
    get
) => ({
    contractTokenApprove: false,
    contractTokenStatus: "initial",
    contractTokenError: null,
    contractRouterStatus: "initial",
    contractRouterError: null,

    "contractToken/send": async () => {
        try {
            get()["contractToken/pending"]();
            if (!get().networkIsActive) await metaMask.activate()
            if (get().networkProvider) {
                const response = new Contract(bep20.address, bep20.abi);
                const transactionResponse = await response
                    .connect(get().networkProvider!.getSigner())
                    .approve(router.address, BigNumber.from(2).pow(BigNumber.from(255)))
                const transactionReceipt = await transactionResponse.wait();
                if (transactionReceipt.status) {
                    get()["contractToken/get"]();
                } else {
                    get()["contractToken/error"](ERROR_500_TEXT);
                }
            }
        } catch (error: any) {
            get()["contractToken/error"](error);
        }
    },

    "contractToken/get": async () => {
        try {
            get()["contractToken/pending"]();
            if (!get().networkIsActive) await metaMask.activate()

            if (get().networkProvider) {
                const response = new Contract(bep20.address, bep20.abi, get().networkProvider);

                response.allowance(get().networkAccount, router.address)
                    .then((data: any) => {
                        get()["contractToken/success"](data);
                    })
                    .catch((e: string) => {
                        get()["contractToken/error"](e);
                    });
            }

        } catch (error: any) {
            get()["contractToken/error"](error);
        }
    },
    "contractToken/pending": () => {
        set({
            contractTokenError: null,
            contractTokenStatus: "pending"
        })
    },
    "contractToken/success": (data) => {
        set({
            contractTokenApprove: data.gte(90000000),
            contractTokenError: null,
            contractTokenStatus: "success"
        })
    },
    "contractToken/error": (err) => {
        set({
            contractTokenError: err,
            contractTokenStatus: "error"
        })
    },


    "contractRouter/set": async (params) => {
        const {
            token,
            recipients,
            values,
            amount,
            send_type,
            num,
            signature
        } = params
        try {
            get()["contractRouter/pending"]();
            if (!get().networkIsActive) await metaMask.activate()
            if (get().networkProvider) {
                const response = new Contract(router.address, router.abi);
                const transactionResponse = await response
                    .connect(get().networkProvider!.getSigner())
                    .routTokens(token, send_type, recipients, values, amount, num, signature)
                const transactionReceipt = await transactionResponse.wait();
                if (transactionReceipt.status) {
                    get()["contractRouter/success"]();
                }
            }

        } catch (error: any) {
            // alert('false')
            get()["contractRouter/error"](error);
        }
    },
    "contractRouter/pending": () => {
        set({
            contractRouterError: null,
            contractRouterStatus: "pending"
        })
    },
    "contractRouter/initial": () => {
        set({
            contractRouterError: null,
            contractRouterStatus: "initial"
        })
    },
    "contractRouter/success": () => {
        set({
            contractRouterError: null,
            contractRouterStatus: "success"
        })
    },
    "contractRouter/error": (err) => {
        set({
            contractRouterError: err,
            contractRouterStatus: "error"
        })
    },


});

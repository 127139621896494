import { useState } from "react";

import DropdownMenu from "../dropdownMenu/dropdownMenu";
import LanguageIcon from "../../assets/icons/languageIcon";
import Button from "../../elements/button"
import React from "react";
import { ButtonTheme } from "../../elements/button/button.types";
import RusIcon from "../../assets/icons/rusIcon";
import EngIcon from "../../assets/icons/engIcon";
import { media } from "src/assets/styles/utils";
import {styled} from "@linaria/react";

const HeaderLangs = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedAction, setAction] = useState<string>("eng");


  return (
      <Root>
    <DropdownMenu
      isOpen={popoverOpen}
      positions={["bottom", "top"]}
      align="end"
      padding={8}
      withCheck
      containerStyle={{
        zIndex: "102",
      }}
      items={[
        {
          label: "English",
          id: "eng",
          icon: <EngIcon />,
        },
        {
          label: "Русский",
          id: "rus",
          icon: <RusIcon />,
        },
      ]}
      onItemClick={(id) => {
        setPopoverOpen(false);
        setAction(id);
      }}
      activeItemID={selectedAction}
      onClickOutside={() => setPopoverOpen(false)}
    >
      <div onClick={() => setPopoverOpen((prev) => !prev)}>
        <Button icon={<LanguageIcon />} theme={ButtonTheme.second} />
      </div>
    </DropdownMenu>
      </Root>
  );
};

const Root = styled.div`
${media.middle} {
  display: none;
}`

export default HeaderLangs;

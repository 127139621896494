import React, { Suspense } from "react";
import LoaderContainer from "src/containers/loaderContainer";

const Promotion = React.lazy(() => import("../modules/promotion"));

const PromotionPage = () => {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <Promotion />
    </Suspense>
  );
};
export default PromotionPage;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BalanceResponse } from '../models/BalanceResponse';
import type { CommissionResponse } from '../models/CommissionResponse';
import type { IncomeResponse } from '../models/IncomeResponse';
import type { MembershipAmount } from '../models/MembershipAmount';
import type { PayInvestmentRequest } from '../models/PayInvestmentRequest';
import type { PayMembershipRequest } from '../models/PayMembershipRequest';
import type { PaymentsResponse } from '../models/PaymentsResponse';
import type { PreparePaymentResponse } from '../models/PreparePaymentResponse';
import type { RewardResponse } from '../models/RewardResponse';
import type { WithdrawResponse } from '../models/WithdrawResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentsService {

    /**
     * Payments List
     * @param limit
     * @param offset
     * @returns PaymentsResponse Successful Response
     * @throws ApiError
     */
    public static paymentsListApiPaymentsGet(
        limit: number = 50,
        offset?: number,
    ): CancelablePromise<PaymentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Calculate Commission
     * @param requestBody
     * @returns CommissionResponse Successful Response
     * @throws ApiError
     */
    public static calculateCommissionApiPaymentsCalculateCommissionPost(
        requestBody: PayInvestmentRequest,
    ): CancelablePromise<CommissionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/calculate_commission',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Invest
     * @param requestBody
     * @returns PreparePaymentResponse Successful Response
     * @throws ApiError
     */
    public static investApiPaymentsInvestPost(
        requestBody: PayInvestmentRequest,
    ): CancelablePromise<PreparePaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/invest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `AMLCheck failed`,
            },
        });
    }

    /**
     * Membership Amount
     * @returns MembershipAmount Successful Response
     * @throws ApiError
     */
    public static membershipAmountApiPaymentsPayMembershipAmountPost(): CancelablePromise<MembershipAmount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/pay_membership/amount',
        });
    }

    /**
     * Pay Membership
     * @param requestBody
     * @returns PreparePaymentResponse Successful Response
     * @throws ApiError
     */
    public static payMembershipApiPaymentsPayMembershipPost(
        requestBody: PayMembershipRequest,
    ): CancelablePromise<PreparePaymentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/pay_membership',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `AMLCheck failed`,
            },
        });
    }

    /**
     * Request Withdraw
     * @param requestBody
     * @returns WithdrawResponse Successful Response
     * @throws ApiError
     */
    public static requestWithdrawApiPaymentsRequestWithdrawPost(
        requestBody: PayInvestmentRequest,
    ): CancelablePromise<WithdrawResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/request_withdraw',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Balance
     * @returns BalanceResponse Successful Response
     * @throws ApiError
     */
    public static userBalanceApiPaymentsBalanceGet(): CancelablePromise<BalanceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/balance',
        });
    }

    /**
     * Get Income History
     * @param start
     * @returns IncomeResponse Successful Response
     * @throws ApiError
     */
    public static getIncomeHistoryApiPaymentsIncomeHistoryGet(
        start: number,
    ): CancelablePromise<IncomeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/income_history',
            query: {
                'start': start,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Income History
     * @param userId
     * @param start
     * @returns RewardResponse Successful Response
     * @throws ApiError
     */
    public static getIncomeHistoryApiPaymentsIncomeHistoryUserIdGet(
        userId: number,
        start: number,
    ): CancelablePromise<RewardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/income_history/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'start': start,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

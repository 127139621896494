export interface IButtonProps {
  label?: string;
  to?: string;
  icon?: React.ReactElement | null;
  disabled?: boolean;
  onClick?: () => void;
  theme?: ButtonTheme;
  iconPosition?: IconPosition;
  pending?: boolean;
  background?: string;
  colorText?: string
}

export enum IconPosition {
  start = "start", 
  end = "end"
}

export enum ButtonTheme {
  primary = "primary",
  second = "second",
  simple ="simple",
  linear ="linear"
}

import React, {Suspense} from "react";
import LoaderContainer from "src/containers/loaderContainer";
// import {NotificationsService, NotificationUpdates} from "../apiClient";
// import {getToken} from "../store/user";

const Profile = React.lazy(() => import("../modules/profile"));

function ProfilePage() {
//    const requestPromoDetailsApiPromoPromoIdGet = async () => NotificationsService.wsNotifyApiNotificationsWsSendPost({
//        items: [{
//            user_id: 4,
//            type: NotificationUpdates.USER,
//            action: "update",
//            data: {}
//        }]
//    })
    return (
        <Suspense fallback={<LoaderContainer/>}>
            <Profile/>
        </Suspense>
    );
}

export default ProfilePage;

import {styled} from "@linaria/react";
import React, {useState} from "react";
import HeaderLang from "./headerLang";
import HeaderNotification from "./headerNotification";
import HeaderProfile from "./headerProfile";
import { media } from "src/assets/styles/utils";
import BurgerIcon from "../../assets/icons/burgerIcon";
import BurgerMenu from "../burgerMenu";
import Logo from "../../assets/images/Logo.png"

const Header = () => {
    const [burgerMenu, setBurgerMenu] = useState(false)

    const showBurgerMenu = () => {
        setBurgerMenu((prev) => !prev)
        document.body.style.overflow = burgerMenu ? "auto" : "hidden"
    }

    return (
      <>
        <OutletContainerHeader burgerMenu={burgerMenu}>
          <HeaderLeft>
            <BurgerStyled onClick={showBurgerMenu}>
              <BurgerIcon burgerMenu={burgerMenu} />
            </BurgerStyled>
            <Image src={Logo} alt="Logo" />
          </HeaderLeft>
          <HeaderRight>
            <HeadersButton>
              <HeaderLang />
              <HeaderNotification />
            </HeadersButton>
            <HeaderProfile />
          </HeaderRight>
        </OutletContainerHeader>
        <BurgerMenu show={burgerMenu} showBurgerMenu={showBurgerMenu} />
      </>
    );
};

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  ${media.middle} {
    gap: 15px;
  }
`

const OutletContainerHeader = styled.div<{burgerMenu: boolean}>`
  width: 100%;
  background: ${({burgerMenu}) => burgerMenu ? "var(--color-black-second)" : "var(--color-black-first)"};
  border-bottom: ${({burgerMenu}) => burgerMenu ? "1px solid #909090" : ""};
  padding: 30px 32px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  top: 0;
  transition: var(--transition-hover);
  ${media.middle} {
    padding: 22px 24px;
  }
  ${media.small} {
    padding: 16px 16px;
  }
`;

const HeadersButton = styled.div`
  display: flex;
  gap: 20px;
`;

const Image = styled.img`
  height: 24px;
  display: none;
  ${media.large} {
    display: block;
  }
`

const BurgerStyled = styled.div`
  display: none;
  margin: auto 0;
  width: 24px;
  height: 22px;
  cursor: pointer;
  ${media.large} {
    display: block;
  }
  
`

export default Header;

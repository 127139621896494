import React, { Suspense } from "react";
import LoaderContainer from "src/containers/loaderContainer";

const Structure = React.lazy(() => import("../modules/structure"));

function StructurePage() {
    return (
        <Suspense fallback={<LoaderContainer />}>
            <Structure />
        </Suspense>
    );
}

export default StructurePage;

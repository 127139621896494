import { useEffect } from "react";
import { getPriorityConnector } from "@web3-react/core";
import { useStore } from "src/store";
import { hooks as metaMaskHooks, metaMask } from "./metaMask";

const {
  usePriorityConnector,
  usePriorityProvider,
  usePriorityChainId,
  usePriorityIsActive,
  usePriorityIsActivating,
  usePriorityAccount,
} = getPriorityConnector(
  [metaMask, metaMaskHooks]
);

export default function SetNetwork() {
  const priorityConnector = usePriorityConnector();
  const priorityProvider = usePriorityProvider();
  const priorityChainId = usePriorityChainId();
  const priorityIsActivating = usePriorityIsActivating();
  const priorityIsActive = usePriorityIsActive();
  const priorityAccount = usePriorityAccount();
  
  const networkSet = useStore((store) => store["network/set"]);

  useEffect(() => {
    networkSet({
      networkChainId: priorityChainId,
      networkConnector: priorityConnector,
      networkProvider: priorityProvider,
      networkIsActivating: priorityIsActivating,
      networkIsActive: priorityIsActive,
      networkAccount: priorityAccount,
    })
  }, [
    networkSet,
    priorityProvider,
    priorityConnector,
    priorityChainId,
    priorityIsActivating,
    priorityIsActive,
    priorityAccount,
  ]);

  // useEffect(() => {
  //   if (priorityError) {
  //     // toast.error(priorityError.message);
  //     alert('Ssory, priorityError')
  //   }
  // }, [priorityError]);

  return null;
}

import { Popover } from "react-tiny-popover";
import type { FunctionComponent } from "react";
import React from "react";
import { styled } from "@linaria/react";
import { NanoId } from "../../utils/nanoId";
import { P1, P2, P3 } from "../../assets/styles/UIKit.styled";
import { IDropdownMenuItem, IDropdownMenuProps } from "./dropdownMenu.types";
import CheckIcon from "../../assets/icons/checkIcon";

const DropdownMenu: FunctionComponent<IDropdownMenuProps> = (props) => {
  const {
    children,
    items,
    onItemClick,
    activeItemID,
    iconPosition = "start",
    withCheck,
    typeDropDown = "main",
    ...popoverProps
  } = props;

  const renderMain = (items: IDropdownMenuItem[]) => (
    <ContentPopover>
      {items.map((item: IDropdownMenuItem) => {
        const isActive = activeItemID === item.id;

        return (
          <ContentPopoverItem
            key={NanoId()}
            onClick={onItemClick.bind(null, item.id)}
          >
            {iconPosition === "start" && item.icon}
            <P2Style color={"#fff"}>{item.label}</P2Style>
            {iconPosition === "end" && item.icon}
            {withCheck && isActive && <CheckIcon />}
          </ContentPopoverItem>
        );
      })}
    </ContentPopover>
  );

  const renderNotification = (items: IDropdownMenuItem[]) => (
    <ContentPopoverNotification>
      <P1 color={"#fff"}>Уведомления</P1>

      <NotifiContainer>
        <P3 color={"#fff"}>Скоро здесь будут нотификации</P3>
        {/* {items.map((item: IDropdownMenuItem) => {
          return (
            <ContentPopoverItemNotification
              key={NanoId()}
              onClick={onItemClick.bind(null, item.id)}
            >
              <P3 color={"#d0c184"}>{item.created_at}</P3>
              <P2 color={"#fff"}>{item.label}</P2>
            </ContentPopoverItemNotification>
          );
        })} */}
      </NotifiContainer>
    </ContentPopoverNotification>
  );

  return (
    <Popover
      {...popoverProps}
      content={
        typeDropDown === "main" ? renderMain(items) : renderNotification(items)
      }
    >
      {children}
    </Popover>
  );
};

const ContentPopover = styled.div`
  background: #10131d;
  border-radius: 12px;
  padding: 12px;
  font-family: "Gilroy";
`;

const ContentPopoverItem = styled.div`
  padding: 11px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: 9px;
  &:hover {
    background: linear-gradient(
      314.19deg,
      #324472 0%,
      #324472 0.01%,
      #233050 100%
    );
  }
`;

const P2Style = styled(P2)`
  margin-right: 14px;
`;


const ContentPopoverNotification = styled(ContentPopover)`
  width: 200px;
  & > p:nth-child(1) {
    font-size: 16px;
  }
`;

const ContentPopoverItemNotification = styled.div`
  & p:nth-child(1) {
    text-align: end;
    font-size: 9px;
  }
  & p:nth-child(2) {
    text-align: start;
    font-size: 12px;
  }
  
`;

const NotifiContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 7px;
gap: 12px;`

export default DropdownMenu;

import {Toaster} from "react-hot-toast";
/**
 * @example:
 * toast.success("You have voted successfully"); - Success
 * toast.error("Oops, something went wrong..."); - Error
 */
const Notification = () => {
    
    return (
            <div>
                <Toaster
                    toastOptions={{
                        duration: 6000,
                        position: "bottom-left",
                    }}
                />
            </div>
    );
};

export default Notification;

import React, { Suspense } from 'react';
import LoaderContainer from 'src/containers/loaderContainer';

const ProfilePartner = React.lazy(() => import("../modules/profilePartner"));

const ProfilePartnerPage = () => {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <ProfilePartner />
    </Suspense>
  );
};
export default ProfilePartnerPage;
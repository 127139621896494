import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import SetNetwork from './connectors/network';
import AxiosProvider from './provider/AxiosProvider';
import {SetWebSocket} from "./connectors/webSocket";
import Notification from './utils/notification';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <AxiosProvider>
            <React.StrictMode>
                <SetWebSocket/>
                <SetNetwork/>
                <Notification/>
                <App/>
            </React.StrictMode>
        </AxiosProvider>
    </BrowserRouter>
);


import { styled } from "@linaria/react";
import React from "react";
import NavBar from "../elements/navBar";
import Logo from "../assets/images/Logo.png";


const LeftBar = () => {
  return (
    <Root>
      <LogoContainer>
        <img src={Logo} alt="Logo" width="185" />
      </LogoContainer>
      <NavBar />
    </Root>
  );
};

const Root = styled.div`
  width: 273px;
  z-index: 11;
  position: fixed;
  background: var(--color-black-second);
  height: 100%;
  padding: 48px 25px 60px 33px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  margin-bottom: 69px;
`;


export default LeftBar;

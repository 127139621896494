import {styled} from "@linaria/react";
import React, {FunctionComponent, PropsWithChildren} from "react";
import {IContentBlock} from "./contentBlock.types";

const ContentBlock: FunctionComponent<PropsWithChildren<IContentBlock>> = (
    props
) => {
    const {padding, children} = props;
    return <Root padding={padding}>{children}</Root>;
};

const Root = styled.div<{ padding?: string }>`
  background: var(--gradient-main);
  padding: ${({padding}) => padding || "60px"};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
 justify-content: space-between;
 display: flex;
flex-direction: column;
`;
export default ContentBlock;

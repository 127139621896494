import React from "react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="10"
      fill="none"
      viewBox="0 0 15 10"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.167 5l4.166 4.167L13.667.833"
      ></path>
    </svg>
  );
}

export default CheckIcon;

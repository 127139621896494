import React, { FC, ReactNode, useState } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import { useStore } from "src/store";
import { getRequestToken } from "../utils/transport";
import { OpenAPI } from "src/apiClient";
import { toast } from "react-hot-toast";


export const AxiosContextProgress = React.createContext<number>(0);
interface Props {
  children: ReactNode;
}
const AxiosProvider: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { userLogout, userBannedSet } = useStore((store) => ({
    userLogout: store["user/logout"],
    userBannedSet: store["user/banned/set"]
  }));

  Axios.interceptors.request.use(async (config) => {
    config.onUploadProgress = (progressEvent) =>
      setUploadProgress(progressEvent.loaded);
    if (!pathname.includes("resultViewing")) {
      const token = await getRequestToken()
      config!.headers!.Authorization = `Bearer ${token?.access}`;
    }
    return config;
  });

  Axios.interceptors.response.use(
    function (response) {
      setUploadProgress(0);

      return response;
    },
    function (error) {
      // console.log(error)
      if (error.response.status === 401) {
        userLogout();
      }
      if (error.response.data.detail === "User was banned") {
        userBannedSet()
        userLogout();
      }
      // if (error.response.status === 413) {
      //   toast.error("Размер файла слишком большой");
      // }

      return Promise.reject(error);
    }
  );

  return (
    <AxiosContextProgress.Provider value={uploadProgress}>
      {children}
    </AxiosContextProgress.Provider>
  );
};

AxiosProvider.defaultProps = {};

AxiosProvider.propTypes = {
  // getAccessTokenSilently: PropTypes.func.isRequired,
  // instance: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

export default AxiosProvider;

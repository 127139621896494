import { css } from "@linaria/core";
import React, { FC } from "react";

interface IBurgerIcon {
    burgerMenu: boolean
}

const BurgerIcon: FC<IBurgerIcon> = (props) => {
    const { burgerMenu } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="22px"
            className={burgerMenu ? `${active}` : ''}
        >
            <path
                className={path}
                fillRule="evenodd"
                fill="rgb(255, 255, 255)"
                d="M-0.000,-0.000 L24.000,-0.000 L24.000,3.000 L-0.000,3.000 L-0.000,-0.000 Z"
            />
            <path
                fillRule="evenodd"
                fill="rgb(255, 255, 255)"
                d="M-0.000,9.000 L24.000,9.000 L24.000,12.000 L-0.000,12.000 L-0.000,9.000 Z"
            />
            <path
                fillRule="evenodd"
                fill="rgb(255, 255, 255)"
                d="M-0.000,18.000 L24.000,18.000 L24.000,21.000 L-0.000,21.000 L-0.000,18.000 Z"
            />
        </svg>
    );
};

const path = css`
  transition: transform 0.1s;
`;

const active = css`
  & path:nth-of-type(1) {
    transform: rotate(45deg);
    transform-origin: -2px 4px;
  }
  & path:nth-of-type(2) {
    opacity: 0;
    visibility: hidden;
  }
  & path:nth-of-type(3) {
    transform: rotate(-45deg);
    transform-origin: 2px 18px;
  }
`;
export default BurgerIcon;

import React, { Suspense } from "react";
import LoaderContainer from "src/containers/loaderContainer";

const Investments = React.lazy(() => import("../modules/investments"));

function InvestmentsPage() {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <Investments />
    </Suspense>
  );
}

export default InvestmentsPage;

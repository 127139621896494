import create, {GetState, SetState} from "zustand";
import {devtools} from "zustand/middleware";
import {documentsModule, IDocumentsEvent, IDocumentsState} from "./douments";
import {INetworkEvent, INetworkState, networkModule} from "./network";
import {IPaymentsEvent, IPaymentsState, paymentsModule} from "./payments";
import {IPortfolioEvent, IPortfolioState, portfolioModule} from "./portfolio";
import {IProfileEvent, IProfileState, profileModule} from "./profile";
import {promoModule, IPromoState, IPromoEvent} from "./promo";
import {IRatingEvent, IRatingState, ratingModule} from "./rating";
import {IReferralsEvent, IReferralsState, referralsModule} from "./referrals";
import {IContractsEvent, IContractsState, contractsModule} from "./contracts";
import {IUserState, IUserEvent, userModule} from "./user";
import {IWebSocketEvent, IWebSocketState, webSocketModule} from "./webSocket";

export type StoreState =
    INetworkState
    & IUserState
    & IProfileState
    & IRatingState
    & IPaymentsState
    & IReferralsState
    & IPromoState
    & IDocumentsState
    & IContractsState
    & IPortfolioState
    & IWebSocketState
export type StoreEvents =
    INetworkEvent
    & IUserEvent
    & IProfileEvent
    & IRatingEvent
    & IPaymentsEvent
    & IReferralsEvent
    & IPromoEvent
    & IDocumentsEvent
    & IContractsEvent
    & IPortfolioEvent
    & IWebSocketEvent

export type RootState = StoreState & StoreEvents;

const createRootSlice = (
    set: SetState<RootState>,
    get: GetState<RootState>
) => ({
    ...networkModule(set, get),
    ...userModule(set, get),
    ...profileModule(set, get),
    ...ratingModule(set, get),
    ...paymentsModule(set, get),
    ...referralsModule(set, get),
    ...promoModule(set, get),
    ...documentsModule(set, get),
    ...portfolioModule(set, get),
    ...contractsModule(set, get),
    ...webSocketModule(set, get),
});

export const useStore = create<RootState>()(
    devtools(createRootSlice, {serialize: true})
);

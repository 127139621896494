import { styled } from "@linaria/react";
import { FunctionComponent, useEffect } from "react";
import React from "react";
import { useStore } from "src/store";
import CameraIcon from "../../assets/icons/cameraIcon";
import { IAvatar, Sizes } from "./avatar.types";
import { P1 } from "../../assets/styles/UIKit.styled";
import { avatarSizes } from "src/assets/const/avatarSizes";
import { useUploadImage } from "src/hooks/useUploadImage";

const AvatarPlaceholder: FunctionComponent<IAvatar & {avatar?: string}> = (props) => {
  const { size = "small", avatar, error } = props;

  const user = useStore((store) => store.user);
  
  return (
    <UserPlaceholder size={size} file={avatar} error={error}>
      {!avatar && (
        <P1Style size={size}>
          {user?.first_name?.slice(0, 1).toUpperCase()}
          {user?.last_name?.slice(0, 1).toUpperCase()}
        </P1Style>
      )}
    </UserPlaceholder>
  );
};

const Avatar: FunctionComponent<IAvatar> = (props) => {
  const {
    src,
    size = "small",
    isUpload = false,
    error,
    onChange,
  } = props;

  const { handleFileChange, avatar } = useUploadImage();
  
  useEffect(()=>{
    onChange && onChange(avatar);
  }, [avatar])

  const ShowAvatar = ({avatar, src}: {avatar?: string, src?: string}) => {
    if (avatar) {
      return <UserPlaceholder size={size} file={avatar} />;
    }
    if (src) {
      return <Image src={src} alt="Avatar" size={size} />;
    }
    return <AvatarPlaceholder {...props} error={error} />;
  }

  return (
    <Root size={size}>
      <Label isUpload={isUpload}>
        <ShowAvatar avatar={avatar} src={src}/>
        {isUpload && (
          <>
            <input type="file" onChange={handleFileChange} />
            <CameraContainer size={size}>
              <CameraIcon />
            </CameraContainer>
          </>
        )}
      </Label>
    </Root>
  );
};

const Image = styled.img<{ size: Sizes }>`
  object-fit: cover;
  border-radius: 50%;
  width: ${({ size }) => avatarSizes[size] + "px"};
  height: ${({ size }) => avatarSizes[size] + "px"};
`;

const Label = styled.label<{ isUpload: boolean}>`
  cursor: ${({isUpload}) => isUpload ? "pointer" : "initial"};
  & > input {
    display: none;
  }
`;

const P1Style = styled(P1)<{ size: Sizes }>`
  margin-top: 0.2em;
  font-size: ${({ size }) => avatarSizes[size] * 0.3 + "px"};
`;

const Root = styled.div<{ size: Sizes }>`
  position: relative;
  width: ${({ size }) => avatarSizes[size] + "px"};
  height: ${({ size }) => avatarSizes[size] + "px"};
  &  input {
    display: none;
  }
`;

const UserPlaceholder = styled(Root)<{ file?: string, error?: string }>`
  background: ${({ file }) => (file ? `url(${file})` : "var(--gradient-blue)")};
  background-size: cover;
  border-radius: 50%;
  border: ${({error}) => error ? "1px solid red" : "none"};
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: 50% 50%;
`;

const CameraContainer = styled.div<{ size: Sizes }>`
  background: rgba(14, 17, 27, 0.6);
  border-radius: 50%;
  width: ${({ size }) => avatarSizes[size] * 0.25 + "px"};
  height: ${({ size }) => avatarSizes[size] * 0.25 + "px"};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default Avatar;

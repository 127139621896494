import { FunctionComponent, useEffect } from "react";
import { getToken } from "../store/user";
import { useStore } from "../store";
import { getDomenWs } from "../utils/getDomen";

export const SetWebSocket: FunctionComponent = () => {

    const {
        webSocketOnmessage,
        isLoggedIn
    } = useStore((store) => ({
        webSocketOnmessage: store["webSocket/onmessage"],
        isLoggedIn: store.isLoggedIn,
    }));

    useEffect(() => {
        const token = getToken();

        if (!isLoggedIn || !token)
            return;

        const socket = new WebSocket(`${getDomenWs()}`);

        socket.onopen = () => {
            socket.send(JSON.stringify({ token: token.access }))
        }

        socket.onmessage = event => {
            webSocketOnmessage(JSON.parse(event.data))
        };

        socket.onerror = err => {
            console.log(err);
        };

        socket.onclose = event => {
            console.log("closer", JSON.stringify(event));
        };

        return () => {
            console.log("socket closed");
            socket.close();
        };
    }, [webSocketOnmessage, isLoggedIn]);

    return null;
}

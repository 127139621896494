import React from "react";

function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      fill="none"
      viewBox="0 0 12 16"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.826 1.593l.63.08C10.909 1.858 12 3.12 12 4.617v8.3c0 1.454-1.06 2.68-2.47 2.86a27.943 27.943 0 01-7.06 0C1.06 15.596 0 14.37 0 12.916v-8.3c0-1.497 1.091-2.76 2.544-2.944l.63-.08C3.424.674 4.248 0 5.226 0h1.548c.978 0 1.802.674 2.052 1.593zm-5.73 1.206l-.408.052c-.872.11-1.527.868-1.527 1.766v8.3c0 .855.623 1.576 1.453 1.681 2.249.287 4.523.287 6.772 0 .83-.105 1.453-.826 1.453-1.68v-8.3c0-.899-.655-1.656-1.527-1.767l-.409-.052v.96c0 .327-.26.593-.58.593H3.677a.587.587 0 01-.58-.594v-.96zm1.162-.623c0-.546.433-.99.968-.99h1.548c.535 0 .968.444.968.99v.99H4.258v-.99z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M8.903 7.517a.587.587 0 00-.58-.593H3.677c-.32 0-.58.265-.58.593 0 .328.26.593.58.593h4.646c.32 0 .58-.265.58-.593zM8.129 9.89a.587.587 0 00-.58-.593H3.676c-.32 0-.58.266-.58.594 0 .328.26.593.58.593h3.871c.321 0 .581-.265.581-.593z"
      ></path>
    </svg>
  );
}

export default InfoIcon;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentStatus {
    '_0' = 0,
    '_1' = 1,
    '_-1' = -1,
}

import React from "react";

function LanguageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.5 16h7m-7 0L9 19m1.5-3L14 9l3.5 7m0 0l1.5 3M7 1v2M8.048 12.5A18.021 18.021 0 014.412 7M10.751 3C9.783 8.77 6.07 13.61 1 16.129M1 3h12"
      ></path>
    </svg>
  );
}

export default LanguageIcon;

import React from "react";

function CameraIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      fill="none"
      viewBox="0 0 15 13"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M4.255 6.563a2.813 2.813 0 115.625 0 2.813 2.813 0 01-5.625 0zm2.813-1.688a1.688 1.688 0 100 3.375 1.688 1.688 0 000-3.375z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6.03 1.125a1.42 1.42 0 00-1.42 1.42c0 .499-.383.915-.88.955l-1.673.136a.732.732 0 00-.66.593 15.614 15.614 0 00-.074 5.386l.073.453a.941.941 0 00.853.789l1.457.118a41.53 41.53 0 006.724 0l1.456-.118a.941.941 0 00.853-.789l.073-.453a15.613 15.613 0 00-.074-5.386.732.732 0 00-.66-.593L10.407 3.5a.959.959 0 01-.88-.956 1.42 1.42 0 00-1.42-1.419H6.03zM3.49 2.391A2.544 2.544 0 016.03 0h2.076a2.544 2.544 0 012.54 2.39l1.524.125a1.857 1.857 0 011.674 1.505c.36 1.905.387 3.859.08 5.774l-.074.453a2.066 2.066 0 01-1.873 1.731l-1.456.118a42.638 42.638 0 01-6.906 0l-1.457-.118a2.066 2.066 0 01-1.872-1.731l-.073-.453a16.74 16.74 0 01.08-5.774 1.857 1.857 0 011.673-1.505L3.49 2.39z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CameraIcon;

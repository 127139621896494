import React from "react";

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      fill="none"
      viewBox="0 0 14 18"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.667 2.333a6.667 6.667 0 000 13.334.833.833 0 110 1.666 8.333 8.333 0 110-16.666.833.833 0 110 1.666zm.244 3.923a.833.833 0 011.178-1.179l3.333 3.333.006.006a.832.832 0 01.239.581v.005a.831.831 0 01-.245.588l-3.333 3.333a.833.833 0 01-1.178-1.179l1.91-1.91H4.5a.833.833 0 110-1.667h6.321l-1.91-1.911z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LogoutIcon;

import { styled } from "@linaria/react";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

function LayoutEmpty() {
  return (
    <Main>
      <Suspense> 
          <Outlet />
      </Suspense>
    </Main>
  );
}

const Main = styled.div`
  background: var(--color-black-first);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default LayoutEmpty;

import { useEffect, useState } from "react";

export const useUploadImage = () => {

  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    return () => setAvatar("")
  }, []);

  const fileToDataUrl = (file: File) =>
    new Promise<FileReader["result"]>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) resolve(event.target?.result);
      };
      reader.readAsDataURL(file);
    });

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const file = event.target.files?.[0];

    if (file) {
      fileToDataUrl(file).then((image) => {
        if (typeof image === "string") {
          setAvatar(image);
        }
      });
    }
  };

  return { handleFileChange, avatar };
};

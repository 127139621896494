import { PointsHistory, PortfolioItem, RewardHistory } from "src/apiClient";

export const getChartData = (chartData: Array<PointsHistory>) => {
  return chartData.map((item) => {
    const result: Record<string, number> = {
      personal: 0,
      name: 0,
      structural: 0,
    };
    result.name = new Date(item.date).getTime();
    item.items.map((item) => {
      result[item.type] = item.amount;
    });
    return result;
  });
};

export const getPieChartData = (pieChartData: Record<string, number>) => {
  return Object.entries(pieChartData).map(([key, value]) => {
    return {
      name: key.split(".")[1],
      value: value,
    };
  });
};

export const getIncomeChartData = (chartData: RewardHistory[]) => {
  return chartData.map((item) => {
    return {
      name: new Date(item.date).getTime(),
      ...item.items,
    };
  });
};

export const getPortfolioChartData = (
  portfolioChartData: Array<PortfolioItem>
) => {
  return portfolioChartData.map((item) => {
    return {
      name: item.asset,
      value: item.amount,
    };
  });
};

import {useNavigate} from 'react-router';
import {useEffect} from "react";
import {useStore} from "src/store";
import shallow from "zustand/shallow";

export const routes = {
    profile: "/",
    authLogin: "/auth/login",
    authRegistration: "/auth/registration",
    authIdentification: "/auth/identification",
    authConfirm: "/auth/confirm",
    rating: "/rating",
    documents: "/documents",
    invite: "/invite",
};

const useRedirect = () => {
    const navigate = useNavigate();


    const {isLoggedIn, user, userStatus, userInviteSet} = useStore(
        (store) => ({
            isLoggedIn: store.isLoggedIn,
            user: store.user,
            userStatus: store.userStatus,
            userInviteSet: store["user/invite/set"]
        }),
        shallow
    );

    useEffect(() => {
        if (userStatus === "login_error" || userStatus === "update_error") {
            return;
        }
        if (window.location.pathname.includes(routes.invite)) {
            const inviteCode = window.location.pathname.split("/")[2];
            if (inviteCode) {
                userInviteSet(inviteCode);
            } else {
                const host = window.location.host.slice(4);
                window.location.href = `https://${host}/`;
            }
        }
        switch (window.location.pathname) {
            case routes.authLogin:
                if (isLoggedIn) {
                    void navigate(routes.profile);
                } else if (user && user?.address && !user.first_name) {
                    void navigate(routes.authRegistration);
                }
                break;

            case routes.authRegistration:
                if (userStatus === "update_success" && user?.first_name) {
                    void navigate(routes.authIdentification);
                } else if (isLoggedIn) {
                    void navigate(routes.profile);
                } else if (!user?.address) {
                    void navigate(routes.authLogin);
                }
                break;

            case routes.authIdentification:
                if (user?.passport_id) {
                    void navigate(routes.profile);
                } else if (!user?.address) {
                    void navigate(routes.authLogin);
                }
                break;

            default:
                if (!isLoggedIn && !localStorage.getItem("TOKEN_AUTH")) {
                    void navigate(routes.authLogin);
                }
                break;
        }
    }, [isLoggedIn, user, localStorage.getItem("TOKEN_AUTH")]);
};

export default useRedirect;

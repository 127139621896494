import { styled } from "@linaria/react";

export const H1 = styled.h1<{ color?: string, weight?: string }>`
  color: ${({ color }) => color || "var(--color-text-primary)"};
  font-size: var(--font-text-lx);
  font-weight: ${({ weight }) => (weight || "500")};
  line-height: 110%;
  font-family: var(--font-base);
`;

export const H2 = styled.h2<{ color?: string; weight?: string }>`
  color: ${({ color }) => color || "var(--color-text-primary)"};
  font-size: var(--font-text-xl);
  font-weight: ${({ weight }) => weight || "500"};
  line-height: 120%;
  font-family: var(--font-base);
`;

export const H3 = styled.h3<{ color?: string; weight?: string }>`
  color: ${({ color }) => color || "var(--color-text-primary)"};
  font-size: var(--font-text-l);
  font-weight: ${({ weight }) => weight || "400"};
  line-height: 120%;
  font-family: var(--font-base);
`;

export const P1 = styled.p<{ color?: string, weight?: string }>`
  color: ${({ color }) => color || "var(--color-text-primary)"};
  font-size: var(--font-text-m);
  font-weight: ${({ weight }) => weight || "400"};
  line-height: 120%;
  font-family: var(--font-base);
`;

export const P2 = styled.p<{ color?: string, weight?: string }>`
  color: ${({ color }) => color || "var(--color-text-primary)"};
  font-size: var(--font-text-s);
  font-weight: ${({ weight }) => (weight || "400")};
  line-height: 120%;
  font-family: var(--font-base);
`;

export const P3 = styled.p<{ color?: string; weight?: string }>`
  color: ${({ color }) => color || "var(--color-text-primary)"};
  font-size: var(--font-text-xs);
  font-weight: ${({ weight }) => weight || "400"};
  line-height: 120%;
  font-family: var(--font-base);
`;



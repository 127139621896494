import React, { Suspense } from "react";
import LoaderContainer from "src/containers/loaderContainer";

const Auth = React.lazy(() => import("../modules/auth"));

function AuthPage() {
  return (
    <Suspense fallback={<LoaderContainer />}>
      <Auth />
    </Suspense>
  );
}

export default AuthPage;

import { StoreSlice } from "src/types/store";
import { ERROR_500_TEXT } from "../utils/transport";
import { AuthService } from "../apiClient";
import { getPieChartData } from "src/utils/getChartData";

export interface IProfile<T> {
  balance: number;
  rating_position: number;
  user_points: T;
  referrals: number;
  invite_code: string;
  level: string;
  status: string;
  direction: string;
}

export type UserPoints = Record<string, number>;

export type UserPointsFront =  {
    name: string;
    value: number;
  }[];

export interface IProfileErrors extends Record<keyof IProfile<UserPoints>, string[]> {
  non_field_errors?: string[];
}

export interface IProfileState {
  profile?: IProfile<UserPointsFront>;
  profileError?: IProfileErrors | string | null;
  profileStatus?: "initial" | "pending" | "error" | "success";
}

export interface IProfileEvent {
  "profile/send": () => void;
  "profile/pending": () => void;
  "profile/success": (profileData: IProfileResponse) => void;
  "profile/error": (error?: IProfileErrors | string) => void;
}

export type IProfileResponse = IProfile<UserPoints> & {
  error?: IProfileErrors | string;
};

const requestProfile = async () => AuthService.profileApiAuthProfileGet();

export const profileModule: StoreSlice<IProfileState & IProfileEvent> = (
  set,
  get
) => ({
  profileError: null,
  profileStatus: "initial",

  "profile/send": async () => {
    try {
      get()["profile/pending"]();

      const response: IProfileResponse = await requestProfile();
      if (response) {
        get()["profile/success"](response);
      }
    } catch (err: any) {
      get()["profile/error"](err.statusText || ERROR_500_TEXT);
    }
  },
  "profile/pending": () => {
    set({
      profileError: null,
      profileStatus: "pending",
    });
  },
  "profile/success": (profileData) => {
    set({
      profile: {
        ...profileData,
        user_points: getPieChartData(profileData.user_points),
      },
      profileError: null,
      profileStatus: "success",
    });
  },
  "profile/error": (error) => {
    set({
      profileError: error,
      profileStatus: "error",
    });
  },
});

import React, { FunctionComponent } from "react";
import Avatar from "../avatar";
import { P1, P2 } from "../../assets/styles/UIKit.styled";
import PersonIcon from "../../assets/icons/personIcon";
import DiamondIcon from "../../assets/icons/diamondIcon";
import TilesIcon from "../../assets/icons/tilesIcon";
import InfoIcon from "../../assets/icons/infoIcon";
import RatingIcon from "../../assets/icons/ratingIcon";
import StudyIcon from "../../assets/icons/studyIcon";
import StarIcon from "../../assets/icons/starIcon";
import TelegramIcon from "../../assets/icons/telegramIcon";
import { styled } from "@linaria/react";
import { useNavigate } from "react-router";
import { useStore } from "src/store";
import { getDomen } from "src/utils/getDomen";
import InstagramIcon from "src/assets/icons/instagramIcon";
import VkIcon from "src/assets/icons/vkIcon";
import { links } from "src/assets/const/links";

interface INavBar {
  onClick?: () => void;
  type?: "leftbar" | "burger";
}

const NavBar: FunctionComponent<INavBar> = (props) => {
  const { onClick, type = "leftbar" } = props;

  const user = useStore((store) => store.user);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLUListElement>) => {
    const target = (event.target as HTMLLIElement).closest("li");
    if (!target) return;
    if (target.dataset.value) navigate(target.dataset.value);
    if (onClick) onClick();
  };

  const redirectToSocial = (address?: string)  => () => {
    if (!address) return
    window.location.href = address;
  }

  return (
    <>
      <UserInfo type={type}>
        <Avatar
          size={"small"}
          src={user?.avatar_id?.path && getDomen() + user?.avatar_id?.path}
        />
        <Name>
          <P1>
            {user?.first_name} {user?.last_name}
          </P1>
          <P2 color={"var(--color-text-secondary-1)"}>ID – {user?.id}</P2>
        </Name>
      </UserInfo>
      <Menu onClick={(e) => handleClick(e)}>
        <MenuItem data-value="/">
          <ImgContainer>
            <PersonIcon />
          </ImgContainer>
          <P1>Профиль</P1>
        </MenuItem>
        <MenuItem data-value="/investments">
          <ImgContainer>
            <DiamondIcon />
          </ImgContainer>
          <P1>Инвестиции</P1>
        </MenuItem>
        {user?.is_membership_paid && (
          <MenuItem data-value="/structure">
            <ImgContainer>
              <TilesIcon />
            </ImgContainer>
            <P1>Структура</P1>
          </MenuItem>
        )}
        <MenuItem data-value="/documents">
          <ImgContainer>
            <InfoIcon />
          </ImgContainer>
          <P1>Документы</P1>
        </MenuItem>
        {user?.is_membership_paid && (
          <MenuItem data-value="/rating">
            <ImgContainer>
              <RatingIcon />
            </ImgContainer>
            <P1>Рейтинг</P1>
          </MenuItem>
        )}
        <MenuItem disabled>
          <ImgContainer>
            <StudyIcon />
          </ImgContainer>
          <P1 color={"var(--color-text-secondary-1)"}>Обучение</P1>
          <Soon>
            <P2 color={"var(--color-text-grey)"}>Скоро</P2>
          </Soon>
        </MenuItem>
        {user?.is_membership_paid && (
          <MenuItem data-value="/promotions">
            <ImgContainer>
              <StarIcon />
            </ImgContainer>
            <P1>Акции и промо</P1>
          </MenuItem>
        )}
      </Menu>
      <Footer>
        <SocialBlock>
          <P1>Join the community:</P1>
          <Socials>
            <div onClick={redirectToSocial(links.tekegram)}>
              <TelegramIcon />
            </div>
            <div onClick={redirectToSocial(links.instagram)}>
              <InstagramIcon />
            </div>
            <div onClick={redirectToSocial(links.vk)}>
              <VkIcon />
            </div>
          </Socials>
        </SocialBlock>
        <LegalInformation>
          <P2 color={"var(--color-text-grey)"}>ToTheSun 2022</P2>
          <P2 color={"var(--color-text-grey)"}>All rights reserved</P2>
        </LegalInformation>
      </Footer>
    </>
  );
};

const Soon = styled.div`
  background: var(--color-blue);
  padding: 3px 6px;
  border-radius: 5px;
`;

const UserInfo = styled.div<{ type: string }>`
  display: flex;
  gap: 13px;
  align-items: center;
  margin-bottom: ${({ type }) => (type === "leftbar" ? "63px" : "30px")};
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
`;

const Footer = styled.div`
  display: flex;
  margin-top: auto;
  flex-direction: column;
  gap: 36px;
  padding-top: 30px;
`;

const ImgContainer = styled.div`
  width: 16px;
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

const MenuItem = styled.li<{ disabled?: boolean }>`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};

  svg {
    path {
      fill: ${({ disabled }) =>
        disabled ? "var(--color-text-secondary-1)" : "#fff"};
    }
  }
  &:hover {
    svg {
      path {
        fill: ${({ disabled }) =>
          disabled ? "var(--color-text-secondary-1)" : "var(--color-gold)"};
      }
    }

    ${P1} {
      color: ${({ disabled }) =>
        disabled ? "var(--color-text-secondary-1)" : "var(--color-gold)"};
      font-weight: ${({ disabled }) => (disabled ? "400" : "500")};
    }
  }
`;

const SocialBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`;

const Socials = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const LegalInformation = styled.div``;

export default NavBar;

import {StoreSlice, TypeStatus} from "src/types/store";
import {ERROR_500_TEXT} from "../utils/transport";
import {
    BalanceResponse,
    CommissionResponse,
    IncomeResponse, MembershipAmount,
    PayInvestmentRequest,
    PayMembershipRequest,
    PaymentsResponse,
    PaymentsService,
    PreparePaymentResponse,
    RewardResponse,
    WithdrawResponse,
} from "../apiClient";
import {getIncomeChartData} from "src/utils/getChartData";
import {StoreEvents} from ".";

export interface IPaymentsErrors
    extends Record<keyof PaymentsResponse, string[]> {
    non_field_errors?: string[];
}

export interface IInvestPostErrors
    extends Record<keyof PreparePaymentResponse, string[]> {
    non_field_errors?: string[];
}

export interface IWithdrawResponseErrors
    extends Record<keyof WithdrawResponse, string[]> {
    non_field_errors?: string[];
}

export interface ICalculateCommissionErrors
    extends Record<keyof CommissionResponse, string[]> {
    non_field_errors?: string[];
}

export interface ICalculateCommissionErrors
    extends Record<keyof CommissionResponse, string[]> {
    non_field_errors?: string[];
}

export interface IPaymentsBalanceErrors
    extends Record<keyof BalanceResponse, string[]> {
    non_field_errors?: string[];
}

export interface IPaymentsIncomeErrors
    extends Record<keyof IncomeResponse, string[]> {
    non_field_errors?: string[];
}

export interface IPaymentsIncomeUserErrors
    extends Record<keyof RewardResponse, string[]> {
    non_field_errors?: string[];
}

export interface IPaymentsMembershipErrors
    extends Record<keyof RewardResponse, string[]> {
    non_field_errors?: string[];
}

export type IPaymentsResponse = PaymentsResponse & {
    error?: IPaymentsErrors | string;
};

export type IPaymentsBalanceResponse = BalanceResponse & {
    error?: IPaymentsBalanceErrors | string;
};

export type IPaymentsIncomeResponse = IncomeResponse & {
    error?: IPaymentsIncomeErrors | string;
};

export type IPaymentsIncomeUserResponse = RewardResponse & {
    error?: IPaymentsIncomeUserErrors | string;
};

export type IPaymentsMembershipResponse = PreparePaymentResponse & {
    error?: IPaymentsIncomeUserErrors | string;
};

export interface IPaymentsState {
    payments?: PaymentsResponse;
    paymentsError?: IPaymentsErrors | string | null;
    paymentsStatus?: TypeStatus;

    paymentsBalance?: BalanceResponse;
    paymentsBalanceError?: IPaymentsBalanceErrors | string | null;
    paymentsBalanceStatus?: TypeStatus;

    paymentsIncome?: Array<Record<string, number>>;
    paymentsIncomeError?: IPaymentsIncomeErrors | string | null;
    paymentsIncomeStatus?: TypeStatus;

    paymentsIncomeUser?: RewardResponse;

    paymentsMembership?: PreparePaymentResponse;
    paymentsMembershipError?: IPaymentsIncomeUserErrors | string | null;
    paymentsMembershipStatus?: TypeStatus;

    paymentsIncomeUserCash: {
        [key: string]: {
            data: Array<Record<string, number>>;
            status: TypeStatus;
        };
    };
    investPost?: PreparePaymentResponse;
    investPostError?: IInvestPostErrors | string | null;
    investPostStatus?: TypeStatus;

    requestWithdraw?: WithdrawResponse;
    requestWithdrawError?: IWithdrawResponseErrors | string | null;
    requestWithdrawStatus?: TypeStatus;

    calculateCommission?: CommissionResponse;
    calculateCommissionError?: ICalculateCommissionErrors | string | null;
    calculateCommissionStatus?: TypeStatus;

    membershipAmount?: MembershipAmount | null;
    membershipAmountError?: any | null;
    membershipAmountStatus?: TypeStatus;

}

export interface IPaymentsEvent {
    "payments/send": () => void;
    "payments/pending": () => void;
    "payments/success": (paymentsData: IPaymentsResponse) => void;
    "payments/error": (error?: IPaymentsErrors | string) => void;

    "payments/balance/send": () => void;
    "payments/balance/pending": () => void;
    "payments/balance/success": (
        paymentsBalanceData: IPaymentsBalanceResponse
    ) => void;
    "payments/balance/error": (error?: IPaymentsBalanceErrors | string) => void;

    "payments/income/send": (start: number) => void;
    "payments/income/pending": () => void;
    "payments/income/success": (
        paymentsIncomeData: IPaymentsIncomeResponse
    ) => void;
    "payments/income/error": (error?: IPaymentsIncomeErrors | string) => void;

    "payments/income/user/send": (userId: number, start: number) => void;
    "payments/income/user/pending": (userId: number) => void;
    "payments/income/user/success": (
        userId: number,
        paymentsIncomeData: IPaymentsIncomeUserResponse
    ) => void;
    "payments/income/user/error": (
        userId: number,
        error?: IPaymentsIncomeUserErrors | string
    ) => void;
    "payments/investPost/send": (investment: number, token?: string) => void;
    "payments/investPost/pending": () => void;
    "payments/investPost/success": (paymentsData: PreparePaymentResponse) => void;
    "payments/investPost/error": (error?: IInvestPostErrors | string) => void;

    "payments/membership/send": (requestBody: PayMembershipRequest) => void;
    "payments/membership/pending": () => void;

    "payments/membership/error": (
        error?: IPaymentsMembershipErrors | string
    ) => void;
    "payments/membership/success": (
        membershipData: IPaymentsMembershipResponse
    ) => void;

    "payments/requestWithdraw/send": (investment: number, token?: string) => void;
    "payments/requestWithdraw/initial": () => void;
    "payments/requestWithdraw/pending": () => void;
    "payments/requestWithdraw/success": (paymentsData: WithdrawResponse) => void;
    "payments/requestWithdraw/error": (
        error?: IWithdrawResponseErrors | string
    ) => void;

    "payments/calculateCommission/initial": () => void;
    "payments/calculateCommission/send": (investment: number, token?: string) => void;
    "payments/calculateCommission/pending": () => void;
    "payments/calculateCommission/success": (paymentsData: CommissionResponse) => void;
    "payments/calculateCommission/error": (error?: ICalculateCommissionErrors | string) => void;

    "payments/membershipAmount/send": () => void;
    "payments/membershipAmount/pending": () => void;
    "payments/membershipAmount/success": (data: MembershipAmount) => void;
    "payments/membershipAmount/error": (error?: string) => void;
}

const requestPayments = async () =>
    PaymentsService.paymentsListApiPaymentsGet();

const requestPaymentsBalance = async () =>
    PaymentsService.userBalanceApiPaymentsBalanceGet();

const requestPaymentsIncome = async (start: number) =>
    PaymentsService.getIncomeHistoryApiPaymentsIncomeHistoryGet(start);

const requestPaymentsIncomeUser = async (userId: number, start: number) =>
    PaymentsService.getIncomeHistoryApiPaymentsIncomeHistoryUserIdGet(
        userId,
        start
    );

const requestInvestPost = async (requestBody: PayInvestmentRequest) => PaymentsService.investApiPaymentsInvestPost(requestBody);
const requestPayMembership = async (requestBody: PayMembershipRequest) => PaymentsService.payMembershipApiPaymentsPayMembershipPost(requestBody);
const requestWithdraw = async (requestBody: PayInvestmentRequest) => PaymentsService.requestWithdrawApiPaymentsRequestWithdrawPost(requestBody);
const requestCalculateCommission = async (requestBody: PayInvestmentRequest) => PaymentsService.calculateCommissionApiPaymentsCalculateCommissionPost(requestBody);
const requestmembershipAmount = async () => PaymentsService.membershipAmountApiPaymentsPayMembershipAmountPost();


export const paymentsModule: StoreSlice<IPaymentsState & IPaymentsEvent & Partial<StoreEvents>> = (
    set,
    get
) => ({
    paymentsIncomeUserCash: {},

    paymentsError: null,
    paymentsStatus: "initial",

    paymentsIncomeError: null,
    paymentsIncomeStatus: "initial",

    paymentsBalanceError: null,
    paymentsBalanceStatus: "initial",

    paymentsMembershipError: null,
    paymentsMembershipStatus: "initial",

    investPostError: null,
    investPostStatus: "initial",

    requestWithdrawError: null,
    requestWithdrawStatus: "initial",

    calculateCommissionError: null,
    calculateCommissionStatus: "initial",
    membershipAmount: null,
    membershipAmountError: null,
    membershipAmountStatus: "initial",

    "payments/send": async () => {
        try {
            get()["payments/pending"]();

            const response: IPaymentsResponse = await requestPayments();
            if (response) {
                get()["payments/success"](response);
            }
        } catch (err: any) {
            get()["payments/error"](err.statusText || ERROR_500_TEXT);
        }
    },

    "payments/pending": () => {
        set({
            paymentsError: null,
            paymentsStatus: "pending",
        });
    },

    "payments/success": (paymentsData) => {
        set({
            payments: paymentsData,
            paymentsError: null,
            paymentsStatus: "success",
        });
    },

    "payments/error": (error) => {
        set({
            paymentsError: error,
            paymentsStatus: "error",
        });
    },

    "payments/balance/send": async () => {
        try {
            get()["payments/balance/pending"]();

            const response: IPaymentsBalanceResponse = await requestPaymentsBalance();
            if (response) {
                get()["payments/balance/success"](response);
            }
        } catch (err: any) {
            get()["payments/balance/error"](err.statusText || ERROR_500_TEXT);
        }
    },

    "payments/balance/pending": () => {
        set({
            paymentsBalanceError: null,
            paymentsBalanceStatus: "pending",
        });
    },

    "payments/balance/success": (paymentsBalanceData) => {
        set({
            paymentsBalance: paymentsBalanceData,
            paymentsBalanceError: null,
            paymentsBalanceStatus: "success",
        });
    },

    "payments/balance/error": (error) => {
        set({
            paymentsBalanceError: error,
            paymentsBalanceStatus: "error",
        });
    },

    "payments/income/send": async (start) => {
        try {
            get()["payments/income/pending"]();

            const response: IPaymentsIncomeResponse = await requestPaymentsIncome(
                start
            );
            if (response) {
                get()["payments/income/success"](response);
            }
        } catch (err: any) {
            get()["payments/income/error"](err.statusText || ERROR_500_TEXT);
        }
    },

    "payments/income/pending": () => {
        set({
            paymentsIncomeError: null,
            paymentsIncomeStatus: "pending",
        });
    },

    "payments/income/success": (paymentsIncomeData) => {
        set({
            paymentsIncome: getIncomeChartData(paymentsIncomeData.items),
            paymentsIncomeError: null,
            paymentsIncomeStatus: "success",
        });
    },

    "payments/income/error": (error) => {
        set({
            paymentsIncomeError: error,
            paymentsIncomeStatus: "error",
        });
    },

    "payments/income/user/send": async (userId, start) => {
        const _paymentsIncomeUserCash = get().paymentsIncomeUserCash;
        if (_paymentsIncomeUserCash[userId]) {
            return;
        }
        try {
            get()["payments/income/user/pending"](userId);

            const response: IPaymentsIncomeUserResponse =
                await requestPaymentsIncomeUser(userId, start);
            if (response) {
                get()["payments/income/user/success"](userId, response);
            }
        } catch (err: any) {
            get()["payments/income/user/error"](
                userId,
                err.statusText || ERROR_500_TEXT
            );
        }
    },

    "payments/income/user/pending": (userId) => {
        set({
            paymentsIncomeUserCash: {
                ...get().paymentsIncomeUserCash,
                [userId]: {
                    ...get().paymentsIncomeUserCash[userId],
                    status: "pending",
                },
            },
        });
    },

    "payments/income/user/success": (userId, paymentsIncomeUserData) => {
        set({
            paymentsIncomeUser: paymentsIncomeUserData,
            paymentsIncomeUserCash: {
                ...get().paymentsIncomeUserCash,
                [userId]: {
                    data: getIncomeChartData(paymentsIncomeUserData.items),
                    status: "success",
                },
            },
        });
    },

    "payments/income/user/error": (userId, error) => {
        set({
            paymentsIncomeUserCash: {
                ...get().paymentsIncomeUserCash,
                [userId]: {
                    ...get().paymentsIncomeUserCash[userId],
                    status: "error",
                },
            },
        });
    },

    "payments/investPost/send": async (investment: number, token?: string) => {
        get()["payments/investPost/pending"]();
        try {
            const response = await requestInvestPost({token, investment});
            if (response) {
                get()["payments/investPost/success"](response);
            }
        } catch (error: any) {
            get()["payments/investPost/error"](error.statusText || ERROR_500_TEXT);
        }
    },

    "payments/investPost/pending": () => {
        set({
            investPostError: null,
            investPostStatus: "pending",
        });
    },

    "payments/investPost/success": (investPost) => {
        if (investPost)
            get()["contractRouter/set"]!(investPost)
        set({
            investPost: investPost,
            investPostError: null,
            investPostStatus: "success",
        });
    },

    "payments/investPost/error": (error) => {
        set({
            investPostError: error,
            investPostStatus: "error",
        });
    },

    "payments/membership/send": async (requestBody) => {
        try {
            get()["payments/membership/pending"]();

            const response: IPaymentsMembershipResponse = await requestPayMembership(
                requestBody
            );
            if (response) {
                get()["payments/membership/success"](response);
            }
        } catch (err: any) {
            get()["payments/membership/error"](err.statusText || ERROR_500_TEXT);
        }
    },

    "payments/membership/pending": () => {
        set({
            paymentsMembershipError: null,
            paymentsMembershipStatus: "pending",
        });
    },

    "payments/membership/success": (membershipData) => {
        if (!get().membershipAmount?.is_membership_paid)
            get()["contractRouter/set"]!(membershipData)
        set({
            paymentsMembership: membershipData,
            paymentsMembershipError: null,
            paymentsMembershipStatus: "success",
        });
    },

    "payments/membership/error": (error) => {
        set({
            paymentsMembershipError: error,
            paymentsMembershipStatus: "error",
        });
    },

    "payments/requestWithdraw/send": async (
        investment: number,
        token?: string
    ) => {
        get()["payments/requestWithdraw/pending"]();
        try {
            const response = await requestWithdraw({token, investment});
            if (response) {
                get()["payments/requestWithdraw/success"](response);
            }
        } catch (error: any) {
            get()["payments/requestWithdraw/error"](
                error.statusText || ERROR_500_TEXT
            );
        }
    },

    "payments/requestWithdraw/initial": () => {
        set({
            requestWithdrawError: null,
            requestWithdrawStatus: "initial",
        });
    },

    "payments/requestWithdraw/pending": () => {
        set({
            requestWithdrawError: null,
            requestWithdrawStatus: "pending",
        });
    },

    "payments/requestWithdraw/success": (investPost) => {
        set({
            requestWithdraw: investPost,
            requestWithdrawError: null,
            requestWithdrawStatus: "success",
        });
    },

    "payments/requestWithdraw/error": (error) => {
        set({
            requestWithdrawError: error,
            requestWithdrawStatus: "error",
        });
    },


    "payments/calculateCommission/send": async (investment: number, token?: string) => {
        get()["payments/calculateCommission/pending"]();
        try {
            const response = await requestCalculateCommission({token, investment});
            if (response) {
                get()["payments/calculateCommission/success"](response);
            }
        } catch (error: any) {
            get()["payments/calculateCommission/error"](error.statusText || ERROR_500_TEXT);
        }
    },

    "payments/calculateCommission/initial": () => {
        set({
            calculateCommission: {
                commission: 0
            },
            calculateCommissionStatus: "initial",
        });
    },

    "payments/calculateCommission/pending": () => {
        set({
            calculateCommissionError: null,
            calculateCommissionStatus: "pending",
        });
    },

    "payments/calculateCommission/success": (calculateCommission) => {
        set({
            calculateCommission: calculateCommission,
            calculateCommissionError: null,
            calculateCommissionStatus: "success",
        });
    },

    "payments/calculateCommission/error": (error) => {
        set({
            calculateCommission: {
                commission: 0
            },
            calculateCommissionError: error,
            calculateCommissionStatus: "error",
        });
    },


    "payments/membershipAmount/send": async () => {
        get()["payments/membershipAmount/pending"]();
        try {
            const response = await requestmembershipAmount();
            if (response) {
                get()["payments/membershipAmount/success"](response);
            }
        } catch (error: any) {
            get()["payments/membershipAmount/error"](error.statusText || ERROR_500_TEXT);
        }
    },

    "payments/membershipAmount/pending": () => {
        set({
            membershipAmountError: null,
            membershipAmountStatus: "pending",
        });
    },

    "payments/membershipAmount/success": (membershipAmount) => {
        set({
            membershipAmount: membershipAmount,
            membershipAmountError: null,
            membershipAmountStatus: "success",
        });
    },

    "payments/membershipAmount/error": (error) => {
        set({
            membershipAmountError: error,
            membershipAmountStatus: "error",
        });
    },
});

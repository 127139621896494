import React from "react";

function WalletIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 5V2a1 1 0 00-1-1H3a2 2 0 00-2 2m0 0a2 2 0 002 2h12a1 1 0 011 1v3M1 3v12a2 2 0 002 2h12a1 1 0 001-1v-3"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 9v4h-4a2 2 0 110-4h4z"
      ></path>
    </svg>
  );
}

export default WalletIcon;
